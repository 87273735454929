/* eslint-disable max-lines */

import { createAsyncThunk } from '@reduxjs/toolkit';

import { activeLoader, disableLoader } from './CommonActions';
import {
	bookingServiceCanDoDirectPickUp,
	bookingServiceDoDirectPickUp,
	bookingServiceStartContractWithSmartKey,
} from '../../data/services/BookingDirectPickUpService';
import { bookingServiceCustomerChange } from '../../data/services/BookingModifyService';
import {
	bookingServiceAllowPending,
	bookingServiceBillBooking,
	bookingServiceGetAvailability,
	bookingServiceGetAvailabilityByNearbyBranch,
	bookingServiceGetByBookingNumber,
	bookingServiceGetByCustomer,
	bookingServiceGetByVehicle,
	bookingServiceGetPendings,
	bookingServiceHasUpgrade,
	bookingServiceInsertCallBack,
	bookingServiceInsertExternal,
	bookingServiceInvoiceableChange,
	bookingServiceRefundPayments,
	bookingServiceRegisterContract,
	bookingServiceRevertToConfirmed,
	bookingServiceSearch,
	bookingServiceSendExtendPaymentEmail,
	bookingServiceSetDropOffData,
	bookingServiceSignContract,
} from '../../data/services/BookingService';
import { branchServiceGetAll } from '../../data/services/BranchService';
import { cardServiceGetByCustomer } from '../../data/services/CardService';
import { customerServiceAcceptGdpr } from '../../data/services/CustomerService';
import { documentServiceGetByBooking } from '../../data/services/DocumentService';
import { paymentLineServiceGetByBooking } from '../../data/services/PaymentLineService';
import { providerServiceGetByBranch } from '../../data/services/ProviderService';
import type {
	IAvailabilityAndPrice,
	IAvailabilityAndPriceByBranch,
	IVehicleGroupAvailabilityAndPrice,
} from '../../models/entities/Availability';
import type { IBooking } from '../../models/entities/Booking';
import type { IBranch } from '../../models/entities/Branch';
import type { ICard } from '../../models/entities/Card';
import type { IDocument } from '../../models/entities/Document';
import type { IPaymentLine } from '../../models/entities/PaymentLine';
import type { IProvider } from '../../models/entities/Provider';
import type { IBookingCustomerChangeParams } from '../../models/serviceParams/BookingModifyParams';
import type {
	IBookingAllowPendingParams,
	IBookingBillBookingParams,
	IBookingCanDoDirectPickUpParams,
	IBookingDoDirectPickUpParams,
	IBookingGetAvailabilityParams,
	IBookingGetByBookingNumberParams,
	IBookingGetByCustomerParams,
	IBookingGetByVehicleParams,
	IBookingGetDetail,
	IBookingGetExtendDetail,
	IBookingGetPendingParams,
	IBookingHasUpgradeParams,
	IBookingInsertExternalParams,
	IBookingInvoiceableChangeParams,
	IBookingRefundPaymentsParams,
	IBookingRevertToConfirmedParams,
	IBookingSearchParams,
	IBookingSendExtendPaymentEmailParams,
	IBookingSetDropOffDataParams,
	IBookingSignAndRegisterContractParams,
	IBookingSignContractParams,
	IBookingStartContractSmartKeyParams,
} from '../../models/serviceParams/BookingParams';
import type { IPaymentCallbackParams } from '../../models/serviceParams/PaymentParams';
import type {
	IBookingDetailResponse,
	IBookingExtendDetailResponse,
	IBookingInsertExternalResponse,
} from '../../models/serviceResponse/BookingResponse';
import { BookingStateType } from '../../models/types/BookingStateType';
import type { MessageType } from '../../models/types/MessageType';
import type { IBookingLine } from '../../modules/booking/bookingLine/entities/BookingLine';
import { bookingLineGetByBookingService } from '../../modules/booking/bookingLine/services/BookingLineGetByBookingService';
import { createSyncAction } from '../../modules/shared/state/createAction';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';
import type { CreationMethod } from '../../modules/shared/types/CreationMethod';
import { ServiceResponse } from '../../modules/shared/types/ServiceResponse';

/**
 * @deprecated Use bookingGetAvailabilityByNearbyBranch from modules/booking/availability/state/actions
 * Retrieves availability and pricing information by nearby branches for a specific booking.
 *
 * @param {IAvailabilityAndPriceByBranch[]} payload - The availability and price data for each nearby branch.
 * @param {BookingAvailabilityParams} params - Parameters for retrieving availability and pricing, such as location, dates, and vehicle requirements.
 * @returns {Promise<void>} - A promise that resolves to an array of availability and pricing information for nearby branches.
 */
export const bookingGetAvailabilityByNearbyBranch = createAsyncAction<
	IAvailabilityAndPriceByBranch[],
	IBookingGetAvailabilityParams
>('booking/getAvailabilityByNearbyBranch', bookingServiceGetAvailabilityByNearbyBranch);

/**
 * @deprecated Use `bookingGetAvailability` from `modules/booking/availability/state/actions`
 * Retrieves availability and pricing information for a specific booking.
 *
 * @param {IAvailabilityAndPrice} payload - The availability and price data.
 * @param {IBookingGetAvailabilityParams} params - Parameters for retrieving availability and pricing.
 */
export const bookingGetAvailability = createAsyncAction<IAvailabilityAndPrice | null, IBookingGetAvailabilityParams>(
	'booking/getAvailability',
	bookingServiceGetAvailability,
);

/**
 * @deprecated Use `bookingUpdateAvailability` from `modules/booking/availability/state/actions`
 */
export const bookingUpdateAvailability = createSyncAction<IAvailabilityAndPrice>('booking/updateAvailability');

/**
 * @deprecated Use `bookingAvailabilityReset` from `modules/booking/availability/state/actions`
 * Resets the state of booking availability.
 */
export const bookingAvailabilityReset = createSyncAction('booking/availabilityReset');

/**
 * Inserts a new external booking.
 *
 * @param {IBookingInsertExternalResponse} payload - The response after inserting the external booking.
 * @param {IBookingInsertExternalParams} params - Parameters for inserting an external booking.
 * @returns {Promise<void>} - A promise that resolves with the response after the booking is inserted.
 */
export const bookingInsertExternal = createAsyncAction<IBookingInsertExternalResponse, IBookingInsertExternalParams>(
	'booking/insertExternal',
	bookingServiceInsertExternal,
);

/**
 * Handles the callback for the booking insertion process.
 *
 * @param {IBookingInsertExternalResponse} payload - The response after processing the booking insertion callback.
 * @param {IPaymentCallbackParams} params - Parameters for the payment callback of the booking insertion.
 * @returns {Promise<void>} - A promise that resolves with the response after processing the callback.
 */
export const bookingInsertCallBack = createAsyncAction<IBookingInsertExternalResponse, IPaymentCallbackParams>(
	'booking/insertCallBack',
	bookingServiceInsertCallBack,
);

/**
 * Retrieves a booking by its booking number.
 *
 * @param {IBooking} payload - The booking to be retrieved.
 * @param {IBookingGetByBookingNumberParams} params - Parameters for retrieving a booking, such as the booking number.
 * @returns {Promise<void>} - A promise that resolves to the booking associated with the given number.
 */
export const bookingGetByBookingNumber = createAsyncAction<IBooking, IBookingGetByBookingNumberParams>(
	'booking/getByBookingNumber',
	bookingServiceGetByBookingNumber,
);

/**
 * @deprecated Use `bookingSignContract` from `modules/booking/pickUp/state/actions`
 * Signs a contract for a booking.
 *
 * @param {IBooking} payload - The booking for which the contract is signed.
 * @param {IBookingSignContractParams} params - Parameters for signing the contract, such as digital signature details.
 * @returns {Promise<void>} - A promise that resolves after the contract is signed.
 */
export const bookingSignContract = createAsyncAction<IBooking, IBookingSignContractParams>(
	'booking/signContract',
	bookingServiceSignContract,
);

/**
 * Searches for bookings based on specified parameters.
 *
 * @param {IBooking[]} payload - The bookings that match the search criteria.
 * @param {IBookingSearchParams} params - Parameters for searching bookings, such as date range, customer ID, or vehicle ID.
 * @returns {Promise<void>} - A promise that resolves to an array of bookings matching the search criteria.
 */
export const bookingSearch = createAsyncAction<IBooking[], IBookingSearchParams>(
	'booking/search',
	bookingServiceSearch,
);

/**
 * Retrieves bookings associated with a specific vehicle.
 *
 * @param {IBooking[]} payload - The bookings associated with the vehicle.
 * @param {IBookingGetByVehicleParams} params - Parameters for retrieving bookings for a specific vehicle.
 * @returns {Promise<void>} - A promise that resolves to an array of bookings for the specified vehicle.
 */
export const bookingGetByVehicle = createAsyncAction<IBooking[], IBookingGetByVehicleParams>(
	'booking/getByVehicle',
	bookingServiceGetByVehicle,
);

/**
 * Retrieves bookings associated with a specific customer.
 *
 * @param {IBooking[]} payload - The bookings associated with the customer.
 * @param {IBookingGetByCustomerParams} params - Parameters for retrieving bookings for a specific customer.
 * @returns {Promise<void>} - A promise that resolves to an array of bookings for the specified customer.
 */
export const bookingGetByCustomer = createAsyncAction<IBooking[], IBookingGetByCustomerParams>(
	'booking/getByCustomer',
	bookingServiceGetByCustomer,
);

/**
 * Retrieves pending bookings.
 *
 * @param {IBooking[]} payload - The pending bookings.
 * @param {IBookingGetPendingParams} params - Parameters for retrieving pending bookings.
 * @returns {Promise<void>} - A promise that resolves to an array of pending bookings.
 */
export const bookingGetPendings = createAsyncAction<IBooking[], IBookingGetPendingParams>(
	'booking/getPendings',
	bookingServiceGetPendings,
);

/**
 * Checks if a booking has an upgrade available.
 *
 * @param {boolean} payload - The result indicating whether an upgrade is available.
 * @param {IBookingHasUpgradeParams} params - Parameters for checking if a booking has an upgrade.
 * @returns {Promise<void>} - A promise that resolves with the result of whether an upgrade is available.
 */
export const bookingHasUpgrade = createAsyncAction<boolean, IBookingHasUpgradeParams>(
	'booking/hasUpgrade',
	bookingServiceHasUpgrade,
);

/**
 * Sends an email for extending the payment of a booking.
 *
 * @param {boolean} payload - The result indicating whether the email was successfully sent.
 * @param {IBookingSendExtendPaymentEmailParams} params - Parameters for sending the extend payment email.
 * @returns {Promise<void>} - A promise that resolves with the result of the email sending process.
 */
export const bookingSendExtendPaymentEmail = createAsyncAction<boolean, IBookingSendExtendPaymentEmailParams>(
	'booking/sendExtendPaymentEmail',
	bookingServiceSendExtendPaymentEmail,
);

/**
 * Resets the booking search state.
 */
export const bookingSearchReset = createSyncAction('booking/searchReset');

/**
 * Bills a specific booking.
 *
 * @param {boolean} payload - The result indicating whether the billing process was successful.
 * @param {IBookingBillBookingParams} params - Parameters for billing the booking, such as booking ID and billing details.
 * @returns {Promise<void>} - A promise that resolves with the result of the billing process.
 */
export const bookingBillBooking = createAsyncAction<boolean, IBookingBillBookingParams>(
	'booking/billBooking',
	bookingServiceBillBooking,
);

/**
 * Refunds payments for a specific booking.
 *
 * @param {boolean} payload - The result indicating whether the refund process was successful.
 * @param {IBookingRefundPaymentsParams} params - Parameters for refunding payments, such as booking ID and refund details.
 * @returns {Promise<void>} - A promise that resolves with the result of the refund process.
 */
export const bookingRefundPayments = createAsyncAction<boolean, IBookingRefundPaymentsParams>(
	'booking/refundPayments',
	bookingServiceRefundPayments,
);

/**
 * @deprecated Use `bookingCanDoDirectPickUp` from `modules/booking/pickUp/state/actions`
 * Checks if a direct pick-up can be performed for a specific booking.
 *
 * @param {boolean} payload - The result indicating whether a direct pick-up is possible.
 * @param {IBookingCanDoDirectPickUpParams} params - Parameters for checking the possibility of a direct pick-up, such as booking ID and related conditions.
 * @returns {Promise<void>} - A promise that resolves with the result of the check.
 */
export const bookingCanDoDirectPickUp = createAsyncAction<boolean, IBookingCanDoDirectPickUpParams>(
	'booking/canDoDirectPickUp',
	bookingServiceCanDoDirectPickUp,
);

/**
 * @deprecated Use `bookingDoDirectPickUp` from `modules/booking/pickup/state/actions`
 * Performs a direct pick-up for a specific booking.
 *
 * @param {IBooking} payload - The booking for which the direct pick-up is performed.
 * @param {IBookingDoDirectPickUpParams} params - Parameters for performing the direct pick-up, such as booking ID and pick-up details.
 * @returns {Promise<void>} - A promise that resolves with the updated booking after the direct pick-up.
 */
export const bookingDoDirectPickUp = createAsyncAction<IBooking, IBookingDoDirectPickUpParams>(
	'booking/doDirectPickUp',
	bookingServiceDoDirectPickUp,
);

/**
 * Reverts a booking to its confirmed state.
 *
 * @param {IBooking} payload - The booking to be reverted.
 * @param {IBookingRevertToConfirmedParams} params - Parameters for reverting the booking, such as booking ID and related conditions.
 * @returns {Promise<void>} - A promise that resolves with the reverted booking.
 */
export const bookingRevertToConfirmed = createAsyncAction<IBooking, IBookingRevertToConfirmedParams>(
	'booking/revertToConfirmed',
	bookingServiceRevertToConfirmed,
);

/**
 * Allows a booking to be marked as pending.
 *
 * @param {IBooking} payload - The booking to be marked as pending.
 * @param {IBookingAllowPendingParams} params - Parameters for marking the booking as pending, such as booking ID and relevant conditions.
 * @returns {Promise<void>} - A promise that resolves with the updated booking after allowing it to be pending.
 */
export const bookingAllowPending = createAsyncAction<IBooking, IBookingAllowPendingParams>(
	'booking/allowPending',
	bookingServiceAllowPending,
);

/**
 * Changes the invoiceable status of a booking.
 *
 * @param {IBooking} payload - The booking for which the invoiceable status is changed.
 * @param {IBookingInvoiceableChangeParams} params - Parameters for changing the invoiceable status, such as booking ID and new status.
 * @returns {Promise<void>} - A promise that resolves with the updated booking after the invoiceable status change.
 */
export const bookingInvoiceableChange = createAsyncAction<IBooking, IBookingInvoiceableChangeParams>(
	'booking/invoiceableChange',
	bookingServiceInvoiceableChange,
);

/**
 * Changes the customer associated with a booking.
 *
 * @param {IBooking} payload - The booking for which the customer is changed.
 * @param {IBookingCustomerChangeParams} params - Parameters for changing the customer, such as booking ID and new customer details.
 * @returns {Promise<void>} - A promise that resolves with the updated booking after the customer change.
 */
export const bookingCustomerChange = createAsyncAction<IBooking, IBookingCustomerChangeParams>(
	'booking/customerChange',
	bookingServiceCustomerChange,
);

/**
 * Clears the booking detail state.
 */
export const bookingDetailClear = createSyncAction('booking/detailClear');

/**
 * Clears the booking search state.
 */
export const bookingClearSearch = createSyncAction('booking/clearSearch');

type taskType =
	| ServiceResponse<IBookingLine[]>
	| ServiceResponse<IPaymentLine[]>
	| ServiceResponse<IDocument[]>
	| ServiceResponse<IBranch[]>
	| ServiceResponse<ICard[]>
	| ServiceResponse<IProvider>
	| ServiceResponse<IAvailabilityAndPrice>;

export const bookingGetDetail = createAsyncAction<IBookingDetailResponse, IBookingGetDetail>(
	'booking/getDetail',
	async (params: IBookingGetDetail) => {
		const bookingResponse = await bookingServiceGetByBookingNumber({ ...params });

		if (bookingResponse.ok && bookingResponse.data) {
			const tasks = [];
			tasks.push(bookingLineGetByBookingService(params));
			tasks.push(paymentLineServiceGetByBooking(params));
			tasks.push(documentServiceGetByBooking(params));
			tasks.push(
				branchServiceGetAll({
					creationMethod: params.creationMethod,
					locale: params.locale,
					token: params.token,
				}),
			);
			tasks.push(
				providerServiceGetByBranch({ branchCode: bookingResponse.data.pickUpBranchCode, token: params.token }),
			);
			const requestResponses = await Promise.all<taskType>(tasks);

			const responseErrors: MessageType[] = [];

			requestResponses.forEach((resp) => {
				if (!resp.ok) {
					responseErrors.push(...resp.errors);
				}
			});

			if (responseErrors.length > 0) {
				return new ServiceResponse<IBookingDetailResponse>(false, null, responseErrors);
			}

			const bookingLine: IBookingLine[] = [];
			const paymentLine: IPaymentLine[] = [];
			const bookingDocument: IDocument[] = [];
			const branches: IBranch[] = [];
			// eslint-disable-next-line init-declarations
			let provider: IProvider | undefined;
			if (requestResponses[0].ok && requestResponses[0].data) {
				bookingLine.push(...(requestResponses[0].data as IBookingLine[]));
			}
			if (requestResponses[1].ok && requestResponses[1].data) {
				paymentLine.push(...(requestResponses[1].data as IPaymentLine[]));
			}
			if (requestResponses[2].ok && requestResponses[2].data) {
				bookingDocument.push(...(requestResponses[2].data as IDocument[]));
			}
			if (requestResponses[3].ok && requestResponses[3].data) {
				branches.push(...(requestResponses[3].data as IBranch[]));
			}

			if (requestResponses[4].ok && requestResponses[4].data) {
				provider = requestResponses[4].data as IProvider;
			}
			let vehicleGroup: IVehicleGroupAvailabilityAndPrice[] = [];
			if (
				bookingResponse.data.bookingState === BookingStateType.Confirmed ||
				bookingResponse.data.bookingState === BookingStateType.OnHire
			) {
				const availaibilityResponse = await bookingServiceGetAvailability({
					agencyCode: bookingResponse.data.agencyCode,
					bookingNumber: bookingResponse.data.bookingNumber,
					creationMethod: params.creationMethod as CreationMethod,
					dropOffBranchCode: bookingResponse.data.dropOffBranchCode,
					dropOffDateTime: bookingResponse.data.dropOffDateTime as Date,
					pickUpBranchCode: bookingResponse.data.pickUpBranchCode,
					pickUpDateTime: bookingResponse.data.pickUpDateTime as Date,
					quoteDateTime: bookingResponse.data.quoteDateTime as Date,
					token: params.token,
					vehicleGroup: bookingResponse.data.vehicleGroupCodeRequested,
					vendorCode: bookingResponse.data.vendorCode,
					disableFilter: true,
					locale: params.locale,
				});
				if (availaibilityResponse.ok) {
					vehicleGroup = (availaibilityResponse.data as IAvailabilityAndPrice).vehicleGroupsAvailability;
				}
			}

			return new ServiceResponse<IBookingDetailResponse>(
				true,
				{
					booking: bookingResponse.data,
					bookingDocuments: bookingDocument,
					bookingLines: bookingLine,
					branches,
					paymentLines: paymentLine,
					provider,
					vehicleGroup,
				},
				[],
			);
		}

		return new ServiceResponse<IBookingDetailResponse>(false, null, bookingResponse.errors);
	},
);

export const bookingGetExtendDetail = createAsyncAction<IBookingExtendDetailResponse, IBookingGetExtendDetail>(
	'booking/getExtendDetail',
	async (params: IBookingGetExtendDetail) => {
		const bookingResponse = await bookingServiceGetByBookingNumber({ ...params });

		if (bookingResponse.ok && bookingResponse.data) {
			const tasks = [];
			tasks.push(bookingLineGetByBookingService(params));
			tasks.push(paymentLineServiceGetByBooking(params));
			tasks.push(branchServiceGetAll({ creationMethod: params.creationMethod, token: params.token }));
			tasks.push(
				cardServiceGetByCustomer({
					creationMethod: params.creationMethod,
					customerCode: bookingResponse.data.customerCode,
					token: params.token,
				}),
			);
			const requestResponses = await Promise.all<taskType>(tasks);

			const responseErrors: MessageType[] = [];

			requestResponses.forEach((resp) => {
				if (!resp.ok) {
					responseErrors.push(...resp.errors);
				}
			});

			// ERROR
			if (responseErrors.length > 0) {
				return new ServiceResponse<IBookingExtendDetailResponse>(true, null, responseErrors);
			}

			const bookingLine: IBookingLine[] = [];
			const paymentLine: IPaymentLine[] = [];
			const branches: IBranch[] = [];
			const cards: ICard[] = [];

			if (requestResponses[0].ok && requestResponses[0].data) {
				bookingLine.push(...(requestResponses[0].data as IBookingLine[]));
			}

			if (requestResponses[1].ok && requestResponses[1].data) {
				paymentLine.push(...(requestResponses[1].data as IPaymentLine[]));
			}
			if (requestResponses[2].ok && requestResponses[2].data) {
				branches.push(...(requestResponses[2].data as IBranch[]));
			}
			if (requestResponses[3].ok && requestResponses[3].data) {
				cards.push(...(requestResponses[3].data as ICard[]));
			}

			return new ServiceResponse<IBookingExtendDetailResponse>(true, {
				booking: bookingResponse.data,
				bookingLines: bookingLine,
				branches,
				cards,
				paymentLines: paymentLine,
			});
		}

		return new ServiceResponse<IBookingExtendDetailResponse>(true, null, bookingResponse.errors);
	},
);

/**
 * Clears the state related to booking insertion.
 */
export const bookingInsertClear = createSyncAction('booking/insertClear');

/**
 * Clears any errors related to booking insertion.
 */
export const bookingInsertClearErrors = createSyncAction('booking/insertClearErrors');

/**
 * Clears the state related to retrieving bookings by a customer.
 */
export const bookingGetByCustomerClear = createSyncAction('booking/getByCustomerClear');

/**
 * Sets drop-off data for a booking.
 *
 * @param {IBooking} payload - The booking to be updated.
 * @param {IBookingSetDropOffDataParams} params - Parameters for setting drop-off data for the booking.
 * @returns {Promise<void>} - A promise that resolves with the updated booking after setting the drop-off data.
 */
export const bookingSetDropOffData = createAsyncAction<IBooking, IBookingSetDropOffDataParams>(
	'booking/setDropOffData',
	bookingServiceSetDropOffData,
);

/**
 * Starts a booking contract using a smart key.
 *
 * @param {boolean} payload - The result indicating whether the contract start was successful.
 * @param {IBookingStartContractSmartKeyParams} params - Parameters for starting the contract with a smart key.
 * @returns {Promise<void>} - A promise that resolves with the result of the contract start process.
 */
export const bookingSmartKeyStartContract = createAsyncAction<boolean, IBookingStartContractSmartKeyParams>(
	'booking/smartKeyStartContract',
	bookingServiceStartContractWithSmartKey,
);

/**
 * Clears the state related to pending bookings.
 */
export const bookingPendingClear = createSyncAction('booking/pendingClear');

export const bookingSignAndRegisterContract = createAsyncThunk<IBooking, IBookingSignAndRegisterContractParams, any>(
	'booking/signAndRegisterContract',
	async (params: IBookingSignAndRegisterContractParams, { dispatch, rejectWithValue }) => {
		const { bookingNumber, computerName, ip, requiredSign } = params;

		try {
			if (requiredSign && computerName && ip) {
				dispatch(
					activeLoader({ icon: 'fa-pencil', message: 'Waiting for customer signature...', active: true }),
				);

				const signResponse = await bookingServiceSignContract({
					bookingNumber,
					computerName,
					ip,
				});

				if (!signResponse.ok) {
					dispatch(disableLoader());
					return rejectWithValue(signResponse.errors);
				}

				const customerResponse = await customerServiceAcceptGdpr({ bookingNumber, computerName, ip });

				dispatch(disableLoader());

				if (!customerResponse.ok) {
					return rejectWithValue(customerResponse.errors);
				}
			}

			dispatch(activeLoader({ icon: 'fa-save', message: 'Awaiting register contract...', active: true }));
			const registerResponse = await bookingServiceRegisterContract({ bookingNumber });
			if (!registerResponse.ok || !registerResponse.data) {
				return rejectWithValue(registerResponse.errors);
			}

			return registerResponse.data;
		} finally {
			dispatch(disableLoader());
		}
	},
);
