/* eslint-disable max-lines */

import { RefundValidStates } from './BookingStatesByOperation';
import { round, sumAmounts } from '../../helpers/numbers';
import type { IAgency } from '../../models/entities/Agency';
import type { IVehicleGroupAvailabilityAndPrice } from '../../models/entities/Availability';
import type { IBooking } from '../../models/entities/Booking';
import type { IBookingPackage } from '../../models/entities/BookingPackage';
import type { ICompany } from '../../models/entities/Company';
import type { ICustomer } from '../../models/entities/Customer';
import type { IPaymentLine } from '../../models/entities/PaymentLine';
import type { IProvider } from '../../models/entities/Provider';
import type { IVehicleGroupPrice } from '../../models/entities/VehicleGroupPrice';
import type { IVendor } from '../../models/entities/Vendor';
import { AmountToInvoiceType } from '../../models/types/AmountToInvoiceType';
import { BookingStateType } from '../../models/types/BookingStateType';
import { BookingType } from '../../models/types/BookingType';
import { CustomerRentType } from '../../models/types/CustomerRentType';
import { CustomerType } from '../../models/types/CustomerType';
import { PaymentChannelType, PaymentMethodType, PaymentOperationType } from '../../models/types/PaymentType';
import type { IBookingLine } from '../../modules/booking/bookingLine/entities/BookingLine';
import { BookingLineType } from '../../modules/booking/bookingLine/types/BookingLineType';
import type { IBookingAmount, IBookingAmounts, IBookingSummaryAmounts } from '../types/IBookingAllAmount';

export const getBookingAllAmount = (bookingLines: IBookingLine[]): IBookingAmounts => ({
	deposit: {
		net: sumAmounts(
			bookingLines.filter((line) => line.bookingLineType === BookingLineType.Deposit),
			'netAmount',
		),
		retail: sumAmounts(
			bookingLines.filter((line) => line.bookingLineType === BookingLineType.Deposit),
			'retailAmount',
		),
	},
	discount: {
		net: 0,
		retail: Math.abs(
			sumAmounts(
				bookingLines
					.filter((lineDiscount) => lineDiscount.bookingLineType === BookingLineType.Discount)
					.map((line) => ({ ...line, price: line.price })),
				'price',
			),
		),
	},
	franchise: {
		net: sumAmounts(
			bookingLines.filter((line) => line.bookingLineType === BookingLineType.Franchise),
			'netAmount',
		),
		retail: sumAmounts(
			bookingLines.filter((line) => line.bookingLineType === BookingLineType.Franchise),
			'retailAmount',
		),
	},
	fuel: {
		net: sumAmounts(
			bookingLines.filter((line) => line.bookingLineType === BookingLineType.Fuel),
			'netAmount',
		),
		retail: sumAmounts(
			bookingLines.filter((line) => line.bookingLineType === BookingLineType.Fuel),
			'retailAmount',
		),
	},
	kilometers: {
		net: sumAmounts(
			bookingLines.filter((line) => line.bookingLineType === BookingLineType.KM),
			'netAmount',
		),
		retail: sumAmounts(
			bookingLines.filter((line) => line.bookingLineType === BookingLineType.KM),
			'retailAmount',
		),
	},
	rental: {
		net: sumAmounts(
			bookingLines.filter((line) => line.bookingLineType === BookingLineType.VehicleGroup),
			'netAmount',
		),
		retail: sumAmounts(
			bookingLines.filter((line) => line.bookingLineType === BookingLineType.VehicleGroup),
			'retailAmount',
		),
	},
	services: {
		net: sumAmounts(
			bookingLines.filter((line) => line.bookingLineType === BookingLineType.Service),
			'netAmount',
		),
		retail: sumAmounts(
			bookingLines.filter((line) => line.bookingLineType === BookingLineType.Service),
			'retailAmount',
		),
	},
	total: {
		net: round(
			sumAmounts(
				bookingLines.filter((line) => line.bookingLineType !== BookingLineType.Discount),
				'netAmount',
			) -
				Math.abs(
					sumAmounts(
						bookingLines.filter((line) => line.bookingLineType === BookingLineType.Discount),
						'netAmount',
					),
				),
		),
		retail: round(
			sumAmounts(
				bookingLines.filter((line) => line.bookingLineType !== BookingLineType.Discount),
				'retailAmount',
			) -
				Math.abs(
					sumAmounts(
						bookingLines.filter((line) => line.bookingLineType === BookingLineType.Discount),
						'retailAmount',
					),
				),
		),
	},
});

export const getBookingSummaryAmountByCustomerType = (
	paymentLines: IPaymentLine[],
	bookingLines: IBookingLine[],
	type: CustomerType[] = [CustomerType.RENT],
): IBookingSummaryAmounts => {
	const isPartnerType = type.includes(CustomerType.TTOO) || type.includes(CustomerType.COMPANY);
	const paymentLineList = paymentLines.filter(
		(paymentLine) =>
			[PaymentOperationType.SALE, PaymentOperationType.PROCESS_PREAUTHORIZATION].includes(
				paymentLine.operation,
			) &&
			paymentLine.customerType &&
			type.includes(paymentLine.customerType),
	);

	// REFUND LINES
	let refundLines = paymentLines.filter(
		(line) =>
			line.operation === PaymentOperationType.REFUND &&
			line.customerType &&
			line.customerType === CustomerType.RENT,
	);

	if (isPartnerType) {
		refundLines = paymentLines.filter(
			(line) =>
				line.operation === PaymentOperationType.REFUND &&
				line.customerType &&
				[CustomerType.TTOO, CustomerType.COMPANY].includes(line.customerType),
		);
	}

	const paid: IBookingAmount = {
		net: isPartnerType ? sumAmounts(paymentLineList, 'amount') - sumAmounts(refundLines, 'amount') : 0,
		retail: isPartnerType ? 0 : sumAmounts(paymentLineList, 'amount') - sumAmounts(refundLines, 'amount'),
	};

	let bookingLineList = bookingLines.filter((line) => !line.invoiceToAgency);
	if (isPartnerType) {
		bookingLineList = bookingLines.filter((line) => line.invoiceToAgency);
	}

	const allAmounts = getBookingAllAmount(bookingLineList);

	const pendingAmount: IBookingAmount = {
		net: round(allAmounts.total.net - paid.net),
		retail: round(allAmounts.total.retail - paid.retail),
	};

	return {
		paid,
		pending: pendingAmount,
		total: allAmounts.total,
	};
};

/**
 * Return booking Total, Pending and Paid amount
 *
 * @returns {{
 * 	total,
 * 	paid,
 * 	pending
 * }}
 */
export const getBookingSummaryAmounts = (
	paymentLines: IPaymentLine[],
	bookingLines: IBookingLine[],
): {
	rentAmounts: IBookingSummaryAmounts;
	agencyAmounts: IBookingSummaryAmounts;
} => {
	const agencyAmounts = getBookingSummaryAmountByCustomerType(paymentLines, bookingLines, [
		CustomerType.COMPANY,
		CustomerType.TTOO,
	]);

	const rentAmounts = getBookingSummaryAmountByCustomerType(paymentLines, bookingLines);

	return {
		agencyAmounts,
		rentAmounts,
	};
};

export const checkCanPrintTicket = (paymentLine: IPaymentLine): boolean => {
	if (paymentLine.method === PaymentMethodType.CARD && paymentLine.ticket) {
		return [
			PaymentOperationType.SALE,
			PaymentOperationType.REFUND,
			PaymentOperationType.PREAUTHORIZATION_ANNULMENT,
			PaymentOperationType.PROCESS_PREAUTHORIZATION,
			PaymentOperationType.PREAUTHORIZATION,
		].includes(paymentLine.operation);
	}

	return false;
};

/**
 * Get payment line in card
 *
 * @return {{
 * 	cardPaymentLines,
 * 	cardRefundLines,
 * 	cardTotalPayment,
 * 	cardTotalRefund,
 * 	cardPending
 * }} Booking payment bookingLines
 */
export const getCardOperations = (
	paymentLines: IPaymentLine[],
): {
	cardPaymentLines: IPaymentLine[];
	cardRefundLines: IPaymentLine[];
	cardTotalPayment: number;
	cardTotalRefund: number;
	cardPending: number;
} => {
	const cardPaymentLines = paymentLines.filter(
		(line) =>
			line.method === PaymentMethodType.CARD &&
			[PaymentChannelType.VIRTUAL, PaymentChannelType.OFFICE].includes(line.channel) &&
			line.operation === PaymentOperationType.SALE,
	);
	const cardRefundLines = paymentLines.filter(
		(line) =>
			line.method === PaymentMethodType.CARD &&
			line.channel === PaymentChannelType.VIRTUAL &&
			line.operation === PaymentOperationType.REFUND,
	);
	const cardTotalPayment = sumAmounts(cardPaymentLines, 'amount');

	const cardTotalRefund = sumAmounts(cardRefundLines, 'amount');
	const cardPending = round(cardTotalPayment - cardTotalRefund);

	return {
		cardPaymentLines,
		cardPending,
		cardRefundLines,
		cardTotalPayment,
		cardTotalRefund,
	};
};

/**
 * Get payment line in card
 *
 * @return {{
 * 	cashPaymentLines,
 * 	cashRefundLines,
 * 	cashTotalPayment,
 * 	cashTotalRefund,
 * 	cashPending
 * }} Booking payment bookingLines
 */
export const getCashOperations = (
	paymentLines: IPaymentLine[],
): {
	cashPaymentLines: IPaymentLine[];
	cashPending: number;
	cashRefundLines: IPaymentLine[];
	cashTotalPayment: number;
	cashTotalRefund: number;
} => {
	const cashPaymentLines = paymentLines.filter(
		(line) =>
			line.method === PaymentMethodType.CASH &&
			line.channel === PaymentChannelType.OFFICE &&
			line.operation === PaymentOperationType.SALE,
	);
	const cashRefundLines = paymentLines.filter(
		(line) =>
			line.method === PaymentMethodType.CASH &&
			line.channel === PaymentChannelType.OFFICE &&
			line.operation === PaymentOperationType.REFUND,
	);
	const cashTotalPayment = sumAmounts(cashPaymentLines, 'amount');
	const cashTotalRefund = sumAmounts(cashRefundLines, 'amount');
	const cashPending = round(cashTotalPayment - cashTotalRefund);

	return {
		cashPaymentLines,
		cashPending,
		cashRefundLines,
		cashTotalPayment,
		cashTotalRefund,
	};
};

/**
 * Get payment bookingLines and amounts in points
 *
 * @param {Array} paymentLines Booking payment bookingLines
 * @returns {{
 * 	pointPaymentLines,
 * 	pointRefundLines,
 * 	pointTotalPayment,
 * 	pointTotalRefund,
 * 	pointPending
 * }} Booking point bookingLines and amounts
 */
export const getPointOperations = (
	paymentLines: IPaymentLine[],
): {
	pointPaymentLines: IPaymentLine[];
	pointPending: number;
	pointRefundLines: IPaymentLine[];
	pointTotalPayment: number;
	pointTotalRefund: number;
} => {
	const pointPaymentLines = paymentLines.filter(
		(line) => line.method === PaymentMethodType.POINTS && line.operation === PaymentOperationType.SALE,
	);
	const pointRefundLines = paymentLines.filter(
		(line) => line.method === PaymentMethodType.POINTS && line.operation === PaymentOperationType.REFUND,
	);
	const pointTotalPayment = sumAmounts(pointPaymentLines, 'amount');
	const pointTotalRefund = sumAmounts(pointRefundLines, 'amount');
	const pointPending = round(pointTotalPayment - pointTotalRefund);

	return {
		pointPaymentLines,
		pointPending,
		pointRefundLines,
		pointTotalPayment,
		pointTotalRefund,
	};
};

export const getCouponOperations = (
	paymentLines: IPaymentLine[],
): {
	couponPaymentLines: IPaymentLine[];
	couponPending: number;
	couponRefundLines: IPaymentLine[];
	couponTotalPayment: number;
	couponTotalRefund: number;
} => {
	const couponPaymentLines = paymentLines.filter(
		(line) => line.method === PaymentMethodType.COUPON && line.operation === PaymentOperationType.SALE,
	);

	const couponRefundLines = paymentLines.filter(
		(line) => line.method === PaymentMethodType.COUPON && line.operation === PaymentOperationType.REFUND,
	);

	const couponTotalPayment = sumAmounts(couponPaymentLines, 'amount');
	const couponTotalRefund = sumAmounts(couponRefundLines, 'amount');
	const couponPending = round(couponTotalPayment - couponTotalRefund);

	return {
		couponPaymentLines,
		couponPending,
		couponRefundLines,
		couponTotalPayment,
		couponTotalRefund,
	};
};

/**
 * Get payment bookingLines and amounts in preAuthorization
 *
 * @param {Array} paymentLines Booking payment bookingLines
 * @returns {{
 * 	preauthorizationPaymentLines,
 * 	preauthorizationAnnulmentLines,
 *  proccessPreauthorizationTotalPayment,
 *  preauthorizationTotalAnnulment,
 * 	preauthorizationPending
 * }} preauthorization bookingLines and amounts
 */
export const getPreauthorizationOperations = (
	paymentLines: IPaymentLine[],
): {
	havePreauthorizationDone: boolean;
	preauthorizationAnnulmentLines: IPaymentLine[];
	preauthorizationPaymentLines: IPaymentLine[];
	preauthorizationPending: number;
	preauthorizationTotal: number;
	preauthorizationTotalAnnulment: number;
	proccessPreauthorizationTotalPayment: number;
	processPreauthorizationLines: IPaymentLine[];
} => {
	const preauthorizationPaymentLines = paymentLines.filter(
		(line) => line.operation === PaymentOperationType.PREAUTHORIZATION,
	);
	const processPreauthorizationLines = paymentLines.filter(
		(line) => line.operation === PaymentOperationType.PROCESS_PREAUTHORIZATION,
	);
	const preauthorizationAnnulmentLines = paymentLines.filter(
		(line) => line.operation === PaymentOperationType.PREAUTHORIZATION_ANNULMENT,
	);

	const proccessPreauthorizationTotalPayment = sumAmounts(processPreauthorizationLines, 'amount');
	const preauthorizationTotalAnnulment = sumAmounts(preauthorizationAnnulmentLines, 'amount');
	const preauthorizationTotal = sumAmounts(preauthorizationPaymentLines, 'amount');
	const havePreauthorizationDone =
		preauthorizationPaymentLines.length -
			processPreauthorizationLines.length -
			preauthorizationAnnulmentLines.length >
		0;

	return {
		havePreauthorizationDone,
		preauthorizationAnnulmentLines,
		preauthorizationPaymentLines,
		preauthorizationPending: proccessPreauthorizationTotalPayment,
		preauthorizationTotal,
		preauthorizationTotalAnnulment,
		proccessPreauthorizationTotalPayment,
		processPreauthorizationLines,
	};
};

/**
 * Get payment bookingLines and amounts in web
 *
 * @return {{
 * 	webPaymentLines,
 * 	webRefundLines,
 * 	webTotalPayment,
 * 	webTotalRefund,
 * 	webMaxToRefund
 * }} paymentLines Booking payment bookingLines
 */
export const getWebOperations = (
	paymentLines: IPaymentLine[],
): {
	webPaymentLines: IPaymentLine[];
	webPending: number;
	webRefundLines: IPaymentLine[];
	webTotalPayment: number;
	webTotalRefund: number;
} => {
	const webPaymentLines = paymentLines.filter(
		(line) =>
			line.channel === PaymentChannelType.WEB &&
			line.method !== PaymentMethodType.COUPON &&
			line.operation === PaymentOperationType.SALE,
	);
	const webRefundLines = paymentLines.filter(
		(line) =>
			line.channel === PaymentChannelType.WEB &&
			line.method !== PaymentMethodType.COUPON &&
			line.operation === PaymentOperationType.REFUND,
	);

	const webTotalPayment = sumAmounts(webPaymentLines, 'amount');
	const webTotalRefund = sumAmounts(webRefundLines, 'amount');
	const webPending = round(webTotalPayment - webTotalRefund);

	return {
		webPaymentLines,
		webPending,
		webRefundLines,
		webTotalPayment,
		webTotalRefund,
	};
};

export const getAmounts = (bookingLines: IBookingLine[]): IBookingAmounts => {
	const depositLines = bookingLines.filter((line) => line.bookingLineType === BookingLineType.Deposit);
	const discountLines = bookingLines
		.filter((line) => line.bookingLineType === BookingLineType.Discount)
		.map((line) => ({ ...line, price: line.price }));
	const franchiseLines = bookingLines.filter((line) => line.bookingLineType === BookingLineType.Franchise);
	const fuelLines = bookingLines.filter((line) => line.bookingLineType === BookingLineType.Fuel);
	const kilometersLines = bookingLines.filter((line) => line.bookingLineType === BookingLineType.KM);
	const rentalLines = bookingLines.filter((line) => line.bookingLineType === BookingLineType.VehicleGroup);
	const servicesLines = bookingLines.filter((line) => line.bookingLineType === BookingLineType.Service);

	const discount = Math.abs(sumAmounts(discountLines, 'retailAmount'));

	return {
		deposit: {
			net: sumAmounts(depositLines, 'netAmount'),
			retail: sumAmounts(depositLines, 'retailAmount'),
		},
		discount: {
			net: 0,
			retail: discount,
		},
		franchise: {
			net: sumAmounts(franchiseLines, 'netAmount'),
			retail: sumAmounts(franchiseLines, 'retailAmount'),
		},
		fuel: {
			net: sumAmounts(fuelLines, 'netAmount'),
			retail: sumAmounts(fuelLines, 'retailAmount'),
		},
		kilometers: {
			net: sumAmounts(kilometersLines, 'netAmount'),
			retail: sumAmounts(kilometersLines, 'retailAmount'),
		},
		rental: {
			net: sumAmounts(rentalLines, 'netAmount'),
			retail: sumAmounts(rentalLines, 'retailAmount'),
		},
		services: {
			net: sumAmounts(servicesLines, 'netAmount'),
			retail: sumAmounts(servicesLines, 'retailAmount'),
		},
		total: {
			net: round(
				sumAmounts(
					bookingLines.filter((line) => line.bookingLineType !== BookingLineType.Discount),
					'netAmount',
				),
			),
			retail: round(
				sumAmounts(
					bookingLines.filter((line) => line.bookingLineType !== BookingLineType.Discount),
					'retailAmount',
				) - discount,
			),
		},
	};
};

/**
 * Return rent and agency amount base in bookinglines
 *
 * @return {{
 * agency: {
 * Rental: AgencyModel.RentalAmount,
 * Deposit: AgencyModel.DepositAmount,
 * Fuel: AgencyModel.FuelAmount,
 * Services: AgencyModel.ServicesAmount,
 * Total: AgencyModel.Total
 * },
 * rent: {
 * Rental: RentModel.RentalAmount,
 * Deposit: RentModel.DepositAmount,
 * Fuel: RentModel.FuelAmount,
 * Services: RentModel.ServicesAmount,
 * Total: RentModel.Total
 * }
 * }} bookingLines BookingLines list
 */
export const getBookingAmounts = (
	bookingLines: IBookingLine[],
): {
	agency: IBookingAmounts;
	rent: IBookingAmounts;
} => {
	const agencyLines = bookingLines.filter((line) => line.invoiceToAgency);
	const rentLines = bookingLines.filter((line) => !line.invoiceToAgency);

	const rentModel = getAmounts(rentLines);
	const agencyModel = getAmounts(agencyLines);
	return {
		agency: {
			deposit: agencyModel.deposit,
			discount: agencyModel.discount,
			franchise: agencyModel.franchise,
			fuel: agencyModel.fuel,
			kilometers: agencyModel.kilometers,
			rental: agencyModel.rental,
			services: agencyModel.services,
			total: agencyModel.total,
		},

		rent: {
			deposit: rentModel.deposit,
			discount: rentModel.discount,
			franchise: rentModel.franchise,
			fuel: rentModel.fuel,
			kilometers: rentModel.kilometers,
			rental: rentModel.rental,
			services: rentModel.services,
			total: rentModel.total,
		},
	};
};

/**
 * Return booking Total, Pending and Paid amount. Only rent amount
 *
 * @returns {{
 * 	total,
 * 	paid,
 * 	pending
 * }}
 */
export const getBookingTotals = (
	paymentLines: IPaymentLine[],
	bookingLines: IBookingLine[],
): {
	paid: IBookingAmount;
	pending: IBookingAmount;
	total: IBookingAmount;
} => {
	const { paid, pending, total } = getBookingSummaryAmountByCustomerType(paymentLines, bookingLines);

	return {
		paid,
		pending,
		total,
	};
};

/**
 * Return Max value can refund with cash
 *
 * @returns {decimal} Max value
 */
export const getMaxCashRefundAmount = (
	paymentLines: IPaymentLine[],
	bookingLines: IBookingLine[],
	bookingState: BookingStateType,
): number => {
	if (!paymentLines || !bookingLines) {
		return 0;
	}

	const { cashPending } = getCashOperations(paymentLines);
	const { pending } = getBookingTotals(paymentLines, bookingLines);

	if (RefundValidStates.includes(bookingState)) {
		return cashPending;
	}

	if (pending.retail >= 0 || cashPending <= 0) {
		return 0;
	}

	return round(Math.min(Math.abs(pending.retail), cashPending));
};

/**
 * Return Max value can pay with cash
 *
 * @returns {decimal} Max value
 */
export const getMaxCashPaymentAmount = (paymentLines: IPaymentLine[], bookingLines: IBookingLine[]): number => {
	const { pending } = getBookingTotals(paymentLines, bookingLines);

	if (pending.retail > 0) {
		return pending.retail;
	}

	return 0;
};

/**
 * Max value can paid with card
 *
 * @returns {decimal} Max value
 */
export const getMaxCardPaymentAmount = (paymentLines: IPaymentLine[], bookingLines: IBookingLine[]): number => {
	if (!paymentLines || !bookingLines) {
		return 0;
	}

	const { pending } = getBookingTotals(paymentLines, bookingLines);

	if (pending.retail > 0) {
		return pending.retail;
	}

	return 0;
};

/**
 * Max value can refund with card
 *
 * @returns {decimal} Max value
 */
export const getMaxCardRefundAmount = (
	paymentLines: IPaymentLine[],
	bookingLines: IBookingLine[],
	bookingState: BookingStateType,
): number => {
	if (!paymentLines || !bookingLines) {
		return 0;
	}

	const { cardPending } = getCardOperations(paymentLines);
	const { preauthorizationPending } = getPreauthorizationOperations(paymentLines);
	const refundPending = round(preauthorizationPending + cardPending);

	const { pending } = getBookingTotals(paymentLines, bookingLines);

	if (RefundValidStates.includes(bookingState)) {
		return refundPending;
	}

	if (pending.retail >= 0 || refundPending <= 0) {
		return 0;
	}

	return round(Math.min(Math.abs(pending.retail), refundPending));
};

/**
 * Max value can proccess preauthorization
 *
 * @returns {decimal} Max value
 */
export const getMaxProcessPreauthorization = (paymentLines: IPaymentLine[], bookingLines: IBookingLine[]): number => {
	if (!paymentLines || !bookingLines) {
		return 0;
	}

	const { pending } = getBookingTotals(paymentLines, bookingLines);

	if (pending.retail < 0) {
		return 0;
	}

	const { preauthorizationPaymentLines } = getPreauthorizationOperations(paymentLines);

	if (preauthorizationPaymentLines.length > 0) {
		const [maxAmount] = preauthorizationPaymentLines.sort(
			(accumulated, current) => current.number - accumulated.number,
		);

		return Math.min(pending.retail, maxAmount.amount);
	}
	return 0;
};

/**
 * Max value can use in preauthorization
 *
 * @returns {decimal} Max value
 */
type MaxPreauthorizationAmountParams = {
	group?: IVehicleGroupPrice;
	company?: ICompany | null;
	bookingType?: BookingType;
};
type MaxPreauthorizationAmountType = (params: MaxPreauthorizationAmountParams) => number;

export const getMaxPreauthorizationAmount: MaxPreauthorizationAmountType = ({
	group,
	bookingType,
	company,
}): number => {
	if (company && bookingType && bookingType === BookingType.COMPANY && !company.directPaymentFranchise) {
		return 0;
	}

	if (group) {
		return group.preAuthorizationAmount;
	}

	return 0;
};

/**
 * Max value can refund with web
 *
 * @returns {decimal} Max value
 */
export const getMaxWebRefundAmount = (
	paymentLines: IPaymentLine[],
	bookingLines: IBookingLine[],
	bookingState: BookingStateType,
): number => {
	if (!paymentLines) {
		return 0;
	}

	const { webPending } = getWebOperations(paymentLines);
	const { pending } = getBookingTotals(paymentLines, bookingLines);

	if (pending.retail < 0 && webPending > 0) {
		return Math.min(Math.abs(pending.retail), webPending);
	}

	if (RefundValidStates.includes(bookingState)) {
		return webPending;
	}

	return 0;
};

/**
 * Max value can paid with web
 *
 * @returns {decimal} Max value
 */
export const getMaxWebPaymentAmount = (paymentLines: IPaymentLine[], bookingLines: IBookingLine[]): number => {
	const { pending } = getBookingTotals(paymentLines, bookingLines);

	if (pending.retail > 0) {
		return pending.retail;
	}

	return 0;
};

/**
 * Max value can refund with point
 *
 * @returns {decimal} Max value
 */
export const getMaxPointRefund = (paymentLines: IPaymentLine[], bookingLines: IBookingLine[]): number => {
	const { pointTotalPayment, pointTotalRefund } = getPointOperations(paymentLines);
	const { pending } = getBookingTotals(paymentLines, bookingLines);

	const maxPointRefund = round(Math.abs(pointTotalPayment - pointTotalRefund));

	return Math.abs(pending.retail > maxPointRefund ? maxPointRefund : pending.retail);
};

/**
 * Return max amount can use with point payment method
 */
export const getMaxPointPaymentAmount = (
	paymentLines: IPaymentLine[],
	bookingLines: IBookingLine[],
	agencyCode: string,
	provider: IProvider,
	customer?: ICustomer | null,
): number => {
	const points = customer ? Number(customer.points) : 0;
	const customerPoints = points;
	if (
		(customer && customer.customerRentType !== CustomerRentType.GoldClub) ||
		agencyCode !== provider.vipAgencyCode ||
		customerPoints < provider.minimumPointsGC
	) {
		return 0;
	}

	const { pointPending } = getPointOperations(paymentLines);

	const discountAmount = sumAmounts(
		bookingLines.filter((line) => line.bookingLineType === BookingLineType.Discount),
		'retailAmount',
	);

	const {
		rent: { rental },
	} = getBookingAmounts(bookingLines);

	const pointMaxAmount = round(rental.retail - pointPending - discountAmount);

	if (customerPoints >= pointMaxAmount) {
		return pointMaxAmount;
	}

	return customerPoints;
};

/**
 * Si esta configurado el SC, se comprueba si lo tiene en la lineas.
 * Si no tiene Extra SC se añade la preautorización como importe pendiente
 * Despues de que pague el importe pendiente de la reserva
 */
export const getReauthorizationAmount = (
	vehicleGroup: IVehicleGroupAvailabilityAndPrice,
	bookingLines: IBookingLine[],
	provider: IProvider,
): number => {
	const haveSCService = bookingLines.some((line) => line.code === provider.smartCover);
	if (!haveSCService && vehicleGroup) {
		return vehicleGroup.preAuthorizationAmount;
	}

	return 0;
};

export const canUsePreauthorization = (booking: IBooking, company: ICompany): boolean => {
	const { type, bookingState } = booking;
	let canUse =
		![BookingType.STAFF, BookingType.TRANSFER].includes(type) &&
		[BookingStateType.Processing, BookingStateType.OnHire].includes(bookingState);

	if ([BookingType.COMPANY].includes(type) && company) {
		canUse = company.directPaymentFranchise;
	}

	return canUse;
};

/**
 * REESERVAS TIPO EMPRESA
 * - NO OBLIGATORIA - Si directPaymentFranchise === false
 */

export const checkPreauthorizationIsRequired = (
	paymentLines: IPaymentLine[],
	bookingLines: IBookingLine[],
	provider: IProvider,
	vehicleGroup?: IVehicleGroupPrice,
	bookingType?: BookingType,
	company?: ICompany | null,
): boolean => {
	const smartCover = provider?.smartCover || 'SC';
	const haveSC = bookingLines.some((line) => line.code === smartCover);
	const { havePreauthorizationDone } = getPreauthorizationOperations(paymentLines);

	// Si es una reserva de empresa
	if (company && bookingType === BookingType.COMPANY) {
		/*
		 * If (haveSC) {
		 * 	return false;
		 * }
		 */

		if (!company.directPaymentFranchise) {
			// If company pay preauthorization, is not required
			return false;
		}
	}

	if (vehicleGroup && vehicleGroup.preAuthorizationAmount === 0) {
		return false;
	}

	const requiredPaymentLines = paymentLines.filter(
		(line) =>
			![PaymentMethodType.POINTS, PaymentMethodType.CASH].includes(line.method) &&
			line.customerType === CustomerType.RENT,
	);

	// If have SC service, check if all payment is in cash
	if (haveSC && requiredPaymentLines.length === 0) {
		return true;
	}

	if ((!haveSC && !havePreauthorizationDone) || (!havePreauthorizationDone && requiredPaymentLines.length === 0)) {
		return true;
	}

	return haveSC && !havePreauthorizationDone && requiredPaymentLines.length === 0;
};

/**
 * Return Coupon max refund amount
 * @param paymentLines Booking paymentLines
 * @param bookingLines Booking lines
 */
export const getMaxCouponRefundAmount = (
	paymentLines: IPaymentLine[],
	bookingLines: IBookingLine[],
	bookingState: BookingStateType,
): number => {
	const { couponTotalPayment, couponTotalRefund, couponPending } = getCouponOperations(paymentLines);
	const { pending } = getBookingTotals(paymentLines, bookingLines);
	const maxCouponRefund = round(Math.abs(couponTotalPayment - couponTotalRefund));

	if (pending.retail < 0 && maxCouponRefund > 0 && [BookingStateType.Pending].includes(bookingState)) {
		return Math.min(Math.abs(pending.retail), couponPending);
	}

	return 0;
};

/**
 * Return Booking Package Amount
 * @param bookingLines
 */
export const getPackageAmount = (bookingLines: IBookingLine[]): IBookingAmount => {
	return {
		net: sumAmounts(
			bookingLines.filter(
				(line) =>
					line.package || (line.bookingLineType === BookingLineType.VehicleGroup && line.invoiceToAgency),
			),
			'netAmount',
		),
		retail: round(
			sumAmounts(
				bookingLines.filter(
					(line) =>
						line.package ||
						(line.bookingLineType === BookingLineType.VehicleGroup && !line.invoiceToAgency),
				),
				'retailAmount',
			) -
				Math.abs(
					sumAmounts(
						bookingLines
							.filter((line) => line.bookingLineType === BookingLineType.Discount)
							.map((line) => ({ ...line, price: line.price })),
						'retailAmount',
					),
				),
		),
	};
};

/**
 * #### Returns an amount depending on amountToInvoice
 * Returns:
 *	- amount: number
 *	- amountToInvoice: NET(0) | RETAIL(1)
 */
export const getAmountToShow = (agencyAmount?: IBookingAmount, amountToInvoice?: AmountToInvoiceType) => {
	if (!agencyAmount) {
		return {
			amount: 0,
			amountToInvoice: AmountToInvoiceType.RETAIL,
		};
	}

	if (amountToInvoice === AmountToInvoiceType.RETAIL) {
		return {
			amount: agencyAmount.retail,
			amountToInvoice: AmountToInvoiceType.RETAIL,
		};
	}

	return {
		amount: agencyAmount.net,
		amountToInvoice: AmountToInvoiceType.NET,
	};
};

export const getNetAmountFromVehicleGroup = (
	vehicleGroup: IVehicleGroupAvailabilityAndPrice,
	amountToInvoice?: AmountToInvoiceType,
) => {
	const [bookingPackage] = vehicleGroup.packages;
	const usePackageAmount = vehicleGroup.packages.length === 1;

	const netAmount = usePackageAmount
		? getAmountToShow({ net: bookingPackage.netAmount, retail: bookingPackage.retailAmount }, amountToInvoice)
				.amount
		: getAmountToShow({ net: vehicleGroup.netAmount, retail: vehicleGroup.retailAmount }, amountToInvoice).amount;

	return { netAmount };
};

export const getNetAmountFromPackage = (bookingPackage: IBookingPackage, amountToInvoice?: AmountToInvoiceType) => {
	const netAmount = getAmountToShow(
		{ net: bookingPackage.netAmount, retail: bookingPackage.retailAmount },
		amountToInvoice,
	).amount;

	return {
		netAmount,
	};
};

export const getRetailAmountFromVehicleGroup = (vehicleGroup: IVehicleGroupAvailabilityAndPrice) => {
	const [bookingPackage] = vehicleGroup.packages;
	const usePackageAmount = vehicleGroup.packages.length === 1;
	const discountAmount = vehicleGroup.discount ? vehicleGroup.discount.amount : 0;

	if (usePackageAmount) {
		return { retailAmount: round(bookingPackage.retailAmount - discountAmount) };
	}

	return { retailAmount: round(vehicleGroup.retailAmount - discountAmount) };
};

export const getRetailAmountFromPackage = (bookingPackage: IBookingPackage, discount: number) => {
	return { retailAmount: round(bookingPackage.retailAmount - discount, 2) };
};

export const getAmountToInvoiceByPartner = (partner?: IVendor | ICompany | IAgency | null) => {
	if (!partner) {
		// eslint-disable-next-line no-console
		console.warn('getAmountToInvoiceByPartner: Partner is not defined, to calculate amountToInvoice');

		return { amountToInvoice: undefined };
	}

	return { amountToInvoice: partner.amountToInvoice };
};
