import { isSeniorDriver, isYoungDriver } from './BookingExtraDrivers';
import { getInitialServices } from './BookingService';
import { groupBy } from '../../helpers/commons';
import type { IVehicleGroupAvailabilityAndPrice } from '../../models/entities/Availability';
import type { IBooking } from '../../models/entities/Booking';
import type { IBranch } from '../../models/entities/Branch';
import type { ICustomer } from '../../models/entities/Customer';
import type { IProvider } from '../../models/entities/Provider';
import type { IService } from '../../models/entities/Service';
import { BookingStateType } from '../../models/types/BookingStateType';
import type { IBookingLine } from '../../modules/booking/bookingLine/entities/BookingLine';
import { BookingLineType } from '../../modules/booking/bookingLine/types/BookingLineType';

export const checkInvoiceTOAgencyByInvoiceableQuantity = (service: IService, bookingLines: IBookingLine[]): boolean => {
	const addedLines = bookingLines.filter((line) => line.code === service.code && line.invoiceToAgency);

	return addedLines.length < service.invoiceableQuantity;
};

// TODO: ADD extensionNumber
/**
 * Genera una key única, verificando las existentes bookingLines
 * @param bookingLines Booking line model
 */
export const getTempBookingLineKey = (bookingLines: IBookingLine[] = []): number => {
	let isValidKey = false;

	if (bookingLines && bookingLines.length > 0) {
		do {
			const key = Math.round(Math.random() * (1000 - 1)) + 1;
			if (!bookingLines.find((line) => line.key === key)) {
				isValidKey = true;
				return key;
			}
		} while (isValidKey);
	}

	return Math.round(Math.random() * (1000 - 1)) + 1;
};

/**
 * Create bookingLine object base in service
 * @param bookingLine BookingLine model to modify
 * @param service Service model selected
 */
export const getBookingLineFromService = (bookingLines: IBookingLine[], service: IService): IBookingLine => {
	return {
		automatic: service.minimumQuantity > 0,
		bookingLineType: BookingLineType.Service,
		code: service.code,
		description: service.description,
		invoiceToAgency: checkInvoiceTOAgencyByInvoiceableQuantity(service, bookingLines),
		key: getTempBookingLineKey(bookingLines),
		name: service.name,
		netAmount: service.netAmount,
		package: service.included,
		price: service.amount,
		pricePerDay: service.amountPerDay,
		quantity: 1,
		quoteDateTime: new Date().toJSON(),
		retailAmount: service.retailAmount,
	};
};

/**
 * Generate bookingLine rent
 * @param group VehicleGroup
 * @param bookingLines BookingLines in booking
 */
export const getVehicleGroupBookingLine = (
	group: IVehicleGroupAvailabilityAndPrice,
	bookingLines: IBookingLine[],
): IBookingLine => {
	return {
		automatic: true,
		bookingLineType: BookingLineType.VehicleGroup,
		code: group.code,
		description: group.vehicleModelCode,
		key: getTempBookingLineKey(bookingLines),
		netAmount: group.netAmount,
		price: group.amount,
		pricePerDay: group.amountPerDay,
		quantity: 1,
		quoteDateTime: new Date().toJSON(),
		retailAmount: group.retailAmount,
	};
};

/**
 * Generate fuel amount line, base on vehicle group fuel amount property
 * @param group VehicleGroup
 * @param bookingLines BookingLines in booking
 */
export const getFuelBookingLine = (
	group: IVehicleGroupAvailabilityAndPrice,
	bookingLines: IBookingLine[],
): IBookingLine => {
	return {
		automatic: true,
		bookingLineType: BookingLineType.Fuel,
		code: 'fuelAmount',
		description: 'Fuel amount',
		key: getTempBookingLineKey(bookingLines),
		name: 'Fuel amount',
		netAmount: group.fuelAmount,
		price: group.fuelAmount,
		pricePerDay: 0,
		quantity: 1,
		quoteDateTime: new Date().toJSON(),
		retailAmount: group.fuelAmount,
	};
};

/**
 * Generate deposit amount line, base on vehicle group deposit amount property
 * @param group VehicleGroup
 * @param bookingLines BookingLines in booking
 */
export const getDepositBookingLine = (
	group: IVehicleGroupAvailabilityAndPrice,
	bookingLines: IBookingLine[],
): IBookingLine => {
	return {
		automatic: true,
		bookingLineType: BookingLineType.Deposit,
		code: 'deposit',
		description: 'Deposit amount',
		key: getTempBookingLineKey(bookingLines),
		name: 'Deposit amount',
		netAmount: group.depositAmount,
		price: group.depositAmount,
		pricePerDay: 0,
		quantity: 1,
		quoteDateTime: new Date().toJSON(),
		retailAmount: group.depositAmount,
	};
};

/**
 * Filter initial services
 * - Package services
 * - Required services
 * - Include service
 *
 * @param provider Currente use provider configuration
 * @param group Vehicle group
 * @param packageCode Package code selected (optional)
 */
export const getInitialBookingLines = (
	provider: IProvider,
	group: IVehicleGroupAvailabilityAndPrice,
	packageCode?: string,
	currentServices?: IService[],
	pickUpBranch?: IBranch,
): IBookingLine[] => {
	const vehicleBookingLine = getVehicleGroupBookingLine(group, []);
	const initialBookingLines: IBookingLine[] = [vehicleBookingLine];
	const mandatoryServices = getInitialServices(provider, group, packageCode, currentServices);

	if (group.discount && group.discount.percentage > 0) {
		initialBookingLines.push({
			bookingLineType: BookingLineType.Discount,
			code: group.discount.code ? group.discount.code : '',
			description: '',
			key: getTempBookingLineKey(initialBookingLines),
			netAmount: 0,
			price: group.discount.amount,
			pricePerDay: 0,
			quantity: 1,
			quoteDateTime: vehicleBookingLine.quoteDateTime,
			retailAmount: group.discount.amount,
		});
	}

	if (pickUpBranch && pickUpBranch.unattended && group.fuelAmount > 0) {
		initialBookingLines.push(getFuelBookingLine(group, []));
	}

	// GENERATE BOOKING LINES
	const mandatoryBookingLines = mandatoryServices.map((service) =>
		getBookingLineFromService(initialBookingLines, service),
	);

	initialBookingLines.push(...mandatoryBookingLines);

	return initialBookingLines;
};

/**
 * Check if booking line can be remove
 * @param bookingLine Booking line to ckeck can remove
 * @param booking Current booking values
 * @param provider current provider config
 */
export const checkCanRemoveBookingLine = (
	bookingLine: IBookingLine,
	booking: IBooking,
	provider: IProvider,
	showAgencyAmount: boolean,
): boolean => {
	const { smartCover } = provider;

	if (bookingLine.code === smartCover && booking.bookingState === BookingStateType.OnHire) {
		return false;
	}

	if (
		bookingLine.automatic ||
		bookingLine.package ||
		[
			BookingStateType.Cancelled,
			BookingStateType.NoShow,
			BookingStateType.StopSale,
			BookingStateType.CancelledDueToUnavailability,
			BookingStateType.Denied,
			BookingStateType.Pending,
			BookingStateType.Liquidated,
		].includes(booking.bookingState)
	) {
		return false;
	}

	if (!showAgencyAmount && bookingLine.invoiceToAgency) {
		return false;
	}

	return true;
};

/**
 * Returns the lines that have franchise
 * @param bookingLines current booking lines
 * @param services all services
 */
export const getBookingLinesWithStock = (bookingLines: IBookingLine[], services: IService[]): IBookingLine[] => {
	if (bookingLines.length === 0) {
		return [];
	}

	const serviceWithStock = services.filter((service) => Boolean(service.franchise)).map((service) => service.code);
	const linesWithStock = bookingLines.filter((bookingLine) => serviceWithStock.includes(bookingLine.code));

	return linesWithStock;
};

// TODO: comprobar los extras que se deben añadir YD, SD, ED
export const checkAddAdditionalLines = (
	booking: IBooking,
	bookingLines: IBookingLine[],
	services: IService[],
	customer: ICustomer,
	provider: IProvider,
): IBookingLine[] => {
	const isSd = isSeniorDriver(booking.pickUpDateTime as string, customer.birthDate as string, provider);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const isYd = isYoungDriver(booking.pickUpDateTime as string, customer.birthDate as string, provider);

	if (isSd) {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const sdService = services.find((service) => service.sd);
	}

	return [];
};

/**
 * Group booking lines by code
 * @param bookingLines Booking lines
 *
 */
export const groupBookingLineByCode = (bookingLines: IBookingLine[]): IBookingLine[] => {
	const mappedBookingLines: IBookingLine[] = [];

	const groupedBookingLineByCode = groupBy(bookingLines, (item: IBookingLine) => item.code);

	groupedBookingLineByCode.forEach((lines: IBookingLine[]) => {
		const [firstLine] = lines;
		mappedBookingLines.push({
			...firstLine,
			price: firstLine.price * lines.length,
			quantity: lines.length,
		});
	});

	return mappedBookingLines;
};
