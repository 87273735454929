import { createAction } from '@reduxjs/toolkit';

import {
	bookingServiceCancel,
	bookingServiceCancelChangeOfVehicle,
	bookingServiceCancelSubscription,
	bookingServiceChangeDiscount,
	bookingServiceChangeVehicleOnContract,
	bookingServiceConfirmChangeOfVehicle,
	bookingServiceExtend,
	bookingServiceExtendCallBack,
	bookingServiceGetExtendAvailability,
	bookingServiceModify,
	bookingServicePayment,
	bookingServicePaymentCallBack,
	bookingServiceStartChangeOfVehicle,
	bookingServiceStartChangeOfVehicleAndInsertBookingLines,
} from '../../data/services/BookingModifyService';
import type { IBooking } from '../../models/entities/Booking';
import type { IPaymentTransaction } from '../../models/entities/PaymentTransaction';
import type { IVehicleLine } from '../../models/entities/VehicleLine';
import type {
	IBookingCancelChangeOfVehicleParams,
	IBookingCancelParams,
	IBookingCancelSubscriptionParams,
	IBookingChangeDiscountParams,
	IBookingChangeVehicleOnContractParams,
	IBookingConfirmChangeOfVehicleParams,
	IBookingExtendParams,
	IBookingFlightTrainModifyParams,
	IBookingGetExtendAvailabilityParams,
	IBookingModifyParams,
	IBookingPaymentParams,
	IBookingStartChangeOfVehicleParams,
	IBookingStartChangeOfVehicleParamsAndInsertBookingLines,
} from '../../models/serviceParams/BookingModifyParams';
import type { IPaymentCallbackParams } from '../../models/serviceParams/PaymentParams';
import type { IBookingExtendResponse } from '../../models/serviceResponse/BookingExtendResponse';
import type { IBookingLine } from '../../modules/booking/bookingLine/entities/BookingLine';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';
import type { IVehicle } from '../../modules/vehicle/entities/Vehicle';

/**
 * Modifies an existing booking.
 *
 * @param {IBooking} payload - The booking to be modified.
 * @param {IBookingModifyParams} params - Parameters for modifying the booking.
 * @returns {Promise<void>} - A promise that resolves after the booking is modified.
 */
export const bookingModify = createAsyncAction<IBooking, IBookingModifyParams>('booking/modify', bookingServiceModify);

/**
 * Cancels an existing booking.
 *
 * @param {IBooking} payload - The booking to be cancelled.
 * @param {IBookingCancelParams} params - Parameters for cancelling the booking.
 * @returns {Promise<void>} - A promise that resolves after the booking is cancelled.
 */
export const bookingCancel = createAsyncAction<IBooking, IBookingCancelParams>('booking/cancel', bookingServiceCancel);

/**
 * Clears the booking cancellation state.
 */
export const bookingCancelClear = createAction<void>('booking/cancelClear');

/**
 * Changes the vehicle associated with an ongoing contract.
 *
 * @param {IBooking} payload - The updated booking with the new vehicle.
 * @param {IBookingChangeVehicleOnContractParams} params - Parameters for changing the vehicle on the contract.
 * @returns {Promise<void>} - A promise that resolves after the vehicle on the contract is changed.
 */
export const bookingChangeVehicleOnContract = createAsyncAction<IBooking, IBookingChangeVehicleOnContractParams>(
	'booking/changeVehicleOnContract',
	bookingServiceChangeVehicleOnContract,
);

/**
 * Initiates the process of changing the vehicle for an existing booking.
 *
 * @param {IVehicle} payload - The new vehicle to be assigned to the booking.
 * @param {IBookingStartChangeOfVehicleParams} params - Parameters for starting the change of vehicle process.
 * @returns {Promise<void>} - A promise that resolves after the process to change the vehicle is initiated.
 */
export const bookingStartChangeOfVehicle = createAsyncAction<IVehicle, IBookingStartChangeOfVehicleParams>(
	'booking/startChangeOfVehicle',
	bookingServiceStartChangeOfVehicle,
);

/**
 * Initiates the process of changing the vehicle for an existing booking and adds new booking lines.
 *
 * @param {IVehicle} payload - The new vehicle to be assigned to the booking along with the new booking lines.
 * @param {IBookingStartChangeOfVehicleParamsAndInsertBookingLines} params - Parameters for starting the change of vehicle process and adding new booking lines.
 * @returns {Promise<void>} - A promise that resolves after the process to change the vehicle and add new booking lines is initiated.
 */
export const bookingStartChangeOfVehicleAndInsertBookingLines = createAsyncAction<
	IVehicle,
	IBookingStartChangeOfVehicleParamsAndInsertBookingLines
>('booking/startChangeOfVehicleAndInsertBookingLines', bookingServiceStartChangeOfVehicleAndInsertBookingLines);

/**
 * Confirms the change of vehicle for an existing booking.
 *
 * @param {boolean} payload - The confirmation status of the vehicle change.
 * @param {IBookingConfirmChangeOfVehicleParams} params - Parameters for confirming the change of vehicle.
 * @returns {Promise<void>} - A promise that resolves after the change of vehicle is confirmed.
 */
export const bookingConfirmChangeOfVehicle = createAsyncAction<IVehicleLine[], IBookingConfirmChangeOfVehicleParams>(
	'booking/confirmChangeOfVehicle',
	bookingServiceConfirmChangeOfVehicle,
);

/**
 * Cancels the process of changing the vehicle for an existing booking.
 *
 * @param {IVehicleLine} payload - The vehicle line associated with the cancelled vehicle change.
 * @param {IBookingCancelChangeOfVehicleParams} params - Parameters for cancelling the change of vehicle process.
 * @returns {Promise<void>} - A promise that resolves after the change of vehicle process is cancelled.
 */
export const bookingCancelChangeOfVehicle = createAsyncAction<IVehicleLine, IBookingCancelChangeOfVehicleParams>(
	'booking/cancelChangeOfVehicle',
	bookingServiceCancelChangeOfVehicle,
);

/**
 * Retrieves availability information for extending a booking.
 *
 * @param {IBookingLine[]} payload - The booking lines available for extension.
 * @param {IBookingGetExtendAvailabilityParams} params - Parameters for retrieving extend availability.
 * @returns {Promise<void>} - A promise that resolves to an array of available booking lines for extension.
 */
export const bookingGetExtendAvailability = createAsyncAction<IBookingLine[], IBookingGetExtendAvailabilityParams>(
	'booking/getExtendAvailability',
	bookingServiceGetExtendAvailability,
);

/**
 * Extends an existing booking.
 *
 * @param {IBookingExtendResponse} payload - The response after extending the booking.
 * @param {IBookingExtendParams} params - Parameters for extending the booking.
 * @returns {Promise<void>} - A promise that resolves with the response after the booking is extended.
 */
export const bookingExtend = createAsyncAction<IBookingExtendResponse, IBookingExtendParams>(
	'booking/extend',
	bookingServiceExtend,
);

/**
 * Handles the callback for booking extension payment.
 *
 * @param {IBookingExtendResponse} payload - The response after processing the payment for booking extension.
 * @param {IPaymentCallbackParams} params - Parameters for the payment callback of the booking extension.
 * @returns {Promise<void>} - A promise that resolves with the response after processing the payment callback.
 */
export const bookingExtendCallBack = createAsyncAction<IBookingExtendResponse, IPaymentCallbackParams>(
	'booking/extendCallBack',
	bookingServiceExtendCallBack,
);

/**
 * Resets the availability state for booking extension.
 */
export const bookingExtendAvailabilityReset = createAction('booking/extendAvailabilityReset');

/**
 * Resets extend errors state.
 */
export const bookingExtendResetErrors = createAction('booking/extendResetErrors');

/**
 * Updates selected data for booking extension.
 *
 * @param {{ dropOffTime?: Date | string; dropOffDate?: Date | string; invoiceToCustomer?: boolean; }} payload - The selected data to be updated for booking extension.
 */
export const bookingExtendUpdateSelectedData = createAction<{
	dropOffTime?: Date | string;
	dropOffDate?: Date | string;
	invoiceToCustomer?: boolean;
}>('booking/extendUpdateSelectedData');

/**
 * Changes the discount applied to a booking.
 *
 * @param {number} payload - The new discount amount.
 * @param {IBookingChangeDiscountParams} params - Parameters for changing the discount of the booking.
 * @returns {Promise<void>} - A promise that resolves after the discount is changed.
 */
export const bookingChangeDiscount = createAsyncAction<number, IBookingChangeDiscountParams>(
	'booking/changeDiscount',
	bookingServiceChangeDiscount,
);

/**
 * Processes a payment for a booking.
 *
 * @param {IPaymentTransaction} payload - The payment transaction details.
 * @param {IBookingPaymentParams} params - Parameters for processing the payment.
 * @returns {Promise<void>} - A promise that resolves after the payment is processed.
 */
export const bookingPay = createAsyncAction<IPaymentTransaction, IBookingPaymentParams>(
	'booking/pay',
	bookingServicePayment,
);

/**
 * Handles the callback for booking payment.
 *
 * @param {IPaymentTransaction} payload - The payment transaction details after the callback.
 * @param {IPaymentCallbackParams} params - Parameters for the payment callback.
 * @returns {Promise<void>} - A promise that resolves after processing the payment callback.
 */
export const bookingPayCallBack = createAsyncAction<IPaymentTransaction, IPaymentCallbackParams>(
	'booking/payCallBack',
	bookingServicePaymentCallBack,
);

/**
 * Modifies booking details related to flight or train.
 *
 * @param {IBooking} payload - The updated booking with new flight or train details.
 * @param {IBookingFlightTrainModifyParams} params - Parameters for modifying the booking with new flight or train details.
 * @returns {Promise<void>} - A promise that resolves after the booking is modified.
 */
export const bookingFlightTrainModify = createAsyncAction<IBooking, IBookingFlightTrainModifyParams>(
	'booking/flightTrainModify',
	bookingServiceModify,
);

/**
 * Cancels a subscription associated with a booking.
 *
 * @param {IBooking} payload - The booking with the subscription to be cancelled.
 * @param {IBookingCancelSubscriptionParams} params - Parameters for cancelling the subscription.
 * @returns {Promise<void>} - A promise that resolves after the subscription is cancelled.
 */
export const bookingCancelSubscription = createAsyncAction<IBooking, IBookingCancelSubscriptionParams>(
	'booking/cancelSubscription',
	bookingServiceCancelSubscription,
);
