import type { IBooking } from '../../models/entities/Booking';
import type {
	IBookingCanDoDirectPickUpParams,
	IBookingDoDirectPickUpParams,
	IBookingStartContractSmartKeyParams,
} from '../../models/serviceParams/BookingParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { BookingEndPoints } from '../endPoints/BookingEndPoints';

/**
 * @deprecated Use `bookingCanDoDirectPickUpService` from `modules/booking/pickUp/services`
 * Check can do direct pickup
 * @param params Booking number
 */
export const bookingServiceCanDoDirectPickUp = (
	params: IBookingCanDoDirectPickUpParams,
): Promise<ServiceResponse<boolean>> => {
	return Api.get<boolean, IBookingCanDoDirectPickUpParams>(BookingEndPoints.CAN_DO_DIRECT_PICKUP, params);
};

/**
 * @deprecated Use `bookingDoDirectPickUpService` from `modules/booking/pickUp/services`
 * Do direct pickup
 * @param params booking number
 */
export const bookingServiceDoDirectPickUp = (
	params: IBookingDoDirectPickUpParams,
): Promise<ServiceResponse<IBooking>> => {
	return Api.post<IBooking, IBookingDoDirectPickUpParams>(BookingEndPoints.DO_DIRECT_PICKUP, params);
};

//TODO: MOVE TO MAIN BOOKING MODULE
export const bookingServiceStartContractWithSmartKey = (
	params: IBookingStartContractSmartKeyParams,
): Promise<ServiceResponse<boolean>> => {
	return Api.post<boolean, IBookingStartContractSmartKeyParams>(BookingEndPoints.START_CONTRAT_SMARTKEY, params);
};
