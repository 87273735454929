import { createAsyncThunk } from '@reduxjs/toolkit';
import type { AsyncThunk, Dispatch } from '@reduxjs/toolkit';

import { activeLoader, disableLoader, userUnauthorized } from '../../../redux/actions/CommonActions';
import type { CreationMethod } from '../types/CreationMethod';
import { HttpStatusCodeType } from '../types/HttpStatusCodeType';
import type { ILoaderParams } from '../types/LoaderParams';
import type { MessageType } from '../types/MessageType';
import type { IServiceBaseParams } from '../types/ServiceBaseParams';
import type { ServiceResponse } from '../types/ServiceResponse';

type AsyncThunkConfig = {
	state?: unknown;
	dispatch?: Dispatch;
	extra?: unknown;
	rejectValue?: unknown;
	serializedErrorType?: unknown;
	pendingMeta?: unknown;
	fulfilledMeta?: unknown;
	rejectedMeta?: unknown;
};

/**
 * Configuration type for async thunks.
 * https://redux-toolkit.js.org/usage/usage-with-typescript#manually-defining-thunkapi-types
 */
export type AsyncThunkConfigType = {
	/**
	 * Payload type for the rejected action.
	 */
	rejectValue: MessageType[];
	/**
	 * Extra argument to be passed to the thunk action creator.
	 */
	extra: {
		creationMethod: CreationMethod;
		apiUrl?: string;
		token?: string;
	};
} & AsyncThunkConfig;

/**
 * Represents the return type of an asynchronous action.
 * @template TPayload The type of the action payload.
 * @template TParams The type of the action parameters.
 * @template Z The type of the async thunk configuration.
 */
export type AsyncActionReturnType<
	TPayload,
	TParams extends IServiceBaseParams | undefined = undefined,
	Z extends AsyncThunkConfig = AsyncThunkConfigType,
> = AsyncThunk<TPayload, TParams, Z>;

/**
 * Represents an asynchronous function type that takes an argument of type TArg and returns a Promise of type ServiceResponse<TData>.
 */
type AsyncFunctionType<TPayload, TParams extends IServiceBaseParams | undefined> = TParams extends undefined
	? () => Promise<ServiceResponse<TPayload>>
	: (args: TParams) => Promise<ServiceResponse<TPayload>>;

/**
 * Options of the action
 * @option `emptyCreationMethod` pass true if you don't want a default creationMethod
 */
type AsyncFunctionOptions = {
	emptyCreationMethod?: boolean;
};

const getLoaderConfig = (loaderParams?: ILoaderParams): ILoaderParams => {
	if (loaderParams) {
		return {
			active: Boolean(loaderParams.active),
			icon: loaderParams.icon,
			message: loaderParams.message,
		};
	}

	return { active: true, icon: undefined, message: undefined };
};

/**
 * Creates an async action that can be used with Redux Toolkit's createAsyncThunk.
 *
 * @param actionName - The name of the action.
 * @param asyncFunction - The async function that will be called when the action is dispatched.
 * @param options - The options for the action
 * @returns The created async action.
 *
 * @example
 *
 * export const accidentGetByBooking = createAsyncAction<IAccident[], IAccidentGetByBookingParams>(
 * 	'accident/getByBooking',
 * 	accidentServiceGetByBooking,
 * );
 */
export const createAsyncAction = <TPayload, TParams extends IServiceBaseParams | undefined = undefined>(
	actionName: string,
	asyncFunction: AsyncFunctionType<TPayload, TParams>,
	options?: AsyncFunctionOptions,
): AsyncActionReturnType<TPayload, TParams> =>
	createAsyncThunk<TPayload, TParams, AsyncThunkConfigType>(actionName, async (args: TParams, thunkAPI) => {
		const loaderConfig = getLoaderConfig(args?.loader);

		if (loaderConfig.active) {
			thunkAPI.dispatch(activeLoader(loaderConfig));
		}

		const defaultCreationMethod = options?.emptyCreationMethod ? undefined : thunkAPI.extra.creationMethod;

		const request = await asyncFunction({
			...args,
			creationMethod: args?.creationMethod ?? defaultCreationMethod,
			token: args?.token,
		});

		// CHECK UNAUTHORIZED
		const isUnAuthorize = request.status === HttpStatusCodeType.UNAUTHORIZED;
		if (isUnAuthorize) {
			thunkAPI.dispatch(userUnauthorized());

			if (loaderConfig.active) {
				thunkAPI.dispatch(disableLoader());
			}

			return thunkAPI.rejectWithValue([
				{ message: 'Session expired, you must access again with your password' },
			] as MessageType[]);
		}

		// SUCCESS REQUEST
		if (request.ok) {
			thunkAPI.dispatch(disableLoader());
			// Aserción de tipo TPayload para prevenir los posibles null en data
			return request.data as TPayload;
		}

		if (loaderConfig.active) {
			thunkAPI.dispatch(disableLoader());
		}

		// RETURN ERROR
		return thunkAPI.rejectWithValue(request.errors);
	});
