import { createAction } from '@reduxjs/toolkit';

import { BookingDiscount } from '../../booking/business/BookingDiscount';
import { getInitialBookingLines } from '../../business/booking/BookingLines';
import {
	addService,
	getUpdateAddBookingLines,
	bookingNewSetGroup as getUpdateBookingNewState,
	getUpdateRemoveBookingLines,
	recalculateDiscounts,
	removeService,
} from '../../business/booking/BookingNewManageState';
import { checkChoosableServices, getInitialServices } from '../../business/booking/BookingService';
import {
	bookingNewServiceSetDiscountCoupon,
	bookingNewServiceSetPickUpBranch,
} from '../../data/services/BookingService';
import type { IAgency } from '../../models/entities/Agency';
import type { IVehicleGroupAvailabilityAndPrice } from '../../models/entities/Availability';
import type { ICompany } from '../../models/entities/Company';
import type { ICustomer } from '../../models/entities/Customer';
import type { IProvider } from '../../models/entities/Provider';
import type { IService } from '../../models/entities/Service';
import type { IVendor } from '../../models/entities/Vendor';
import { BookingCoverType } from '../../models/types/BookingCoverType';
import { BookingPackageType } from '../../models/types/BookingPackageType';
import { CustomerRentType } from '../../models/types/CustomerRentType';
import type { UnitTime } from '../../models/types/UnitTime';
import type { IBookingLine } from '../../modules/booking/bookingLine/entities/BookingLine';
import { createSyncAction } from '../../modules/shared/state/createAction';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';
import { CreationMethod } from '../../modules/shared/types/CreationMethod';
import type {
	IBookingNewAddPaymentWithPointsParams,
	IBookingNewDiscountData,
	IBookingNewExternalSetAgency,
	IBookingNewExternalSetVendor,
	IBookingNewRemoveDiscountCouponParams,
	IBookingNewRemovePaymentWithPointsParams,
	IBookingNewSetAvailabilityDataParams,
	IBookingNewSetBranchParams,
	IBookingNewSetCoupon,
	IBookingNewSetCoverParams,
	IBookingNewSetDiscountCouponParams,
	IBookingNewSetDiscountCouponPayload,
	IBookingNewSetDiscountParams,
	IBookingNewSetFilterPackageParams,
	IBookingNewSetVehicleGroupAndPackageParams,
	IBookingNewUpdateServicesAndLinesParams,
	IPartnersBookingNewSetGroupParams,
} from '../../redux-store/actionsParams/BookingNewActionsParams';
import type { IBookingNewSetPickUpBranchPayloadType } from '../types/ActionsPayload';

/**
 * Clears the current session data for a new booking.
 */
export const bookingNewClearSession = createAction('bookingNew/clearSession');

/**
 * Initializes the availability step in the new booking process.
 */
export const bookingNewInitAvailabilityStep = createAction('bookingNew/initAvailabilityStep');

/**
 * Initializes the groups selection step in the new booking process.
 */
export const bookingNewInitGroupsStep = createAction('bookingNew/initGroupsStep');

/**
 * Initializes the services selection step in the new booking process.
 */
export const bookingNewInitServicesStep = createAction('bookingNew/initServicesStep');

/**
 * Initializes the invoice-to selection step in the new booking process.
 */
export const bookingNewInitInvoiceToStep = createAction('bookingNew/initInvoiceToStep');

/**
 * Initializes the confirmation step in the new booking process.
 */
export const bookingNewInitConfirmationStep = createAction('bookingNew/initConfirmationStep');

/**
 * Adds a service to the new booking.
 *
 * @param {IBookingNewUpdateServicesAndLinesParams} payload - The parameters including details of the service to be added to the new booking.
 */
export const bookingNewAddService = createAction(
	'bookingNew/addService',
	(params: IBookingNewUpdateServicesAndLinesParams) => {
		const { service, services, bookingLines } = params;

		if (service) {
			return {
				payload: {
					bookingLines: [...getUpdateAddBookingLines(bookingLines, service)],
					services: [...addService(services, service)],
				},
			};
		}

		throw new Error(`Service property is required. This value is required to add service`);
	},
);

/**
 * Removes a service from the new booking.
 *
 * @param {IBookingNewUpdateServicesAndLinesParams} payload - The parameters including details of the service to be removed from the new booking.
 */
export const bookingNewRemoveService = createAction(
	'bookingNew/removeService',
	(params: IBookingNewUpdateServicesAndLinesParams) => {
		const { service, services, bookingLines } = params;

		if (service) {
			return {
				payload: {
					bookingLines: [...getUpdateRemoveBookingLines(bookingLines, service.code)],
					services: [...removeService(services, service.code)],
				},
			};
		}

		throw new Error(`Service property is required. This value is required to remove service`);
	},
);

/**
 * Sets availability data for the new booking.
 *
 * @param {IBookingNewSetAvailabilityDataParams} payload - The parameters including availability data to be set for the new booking.
 */
export const bookingNewSetAvailabilityData = createAction<IBookingNewSetAvailabilityDataParams>(
	'bookingNew/setAvailabilityData',
);

/**
 * Sets the vehicle group for the new booking.
 *
 * @param {{ group: IVehicleGroupAvailabilityAndPrice; packageCode?: string }} payload - The vehicle group and optional package code to be set for the new booking.
 */
export const bookingNewSetGroup = createAction<{ group: IVehicleGroupAvailabilityAndPrice; packageCode?: string }>(
	'bookingNew/setGroup',
);

/**
 * Updates a booking line in the new booking process.
 *
 * @param {IBookingLine} payload - The booking line to be updated.
 */
export const bookingNewUpdateBookingLine = createAction<IBookingLine>('bookingNew/updateBookingLine');

/**
 * Sets the agency for the new booking.
 *
 * @param {IAgency} payload - The agency to be set for the new booking.
 */
export const bookingNewSetAgency = createAction<IAgency>('bookingNew/setAgency');

/**
 * Sets the vendor for the new booking.
 *
 * @param {IVendor} payload - The vendor to be set for the new booking.
 */
export const bookingNewSetVendor = createAction<IVendor>('bookingNew/setVendor');

/**
 * Sets the company for the new booking.
 *
 * @param {ICompany} payload - The company to be set for the new booking.
 */
export const bookingNewSetCompany = createAction<ICompany>('bookingNew/setCompany');

/**
 * Sets the drop-off branch for the new booking.
 *
 * @param {IBookingNewSetBranchParams} payload - The parameters for setting the drop-off branch for the new booking.
 */
export const bookingNewSetDropOffBranch = createAction(
	'bookingNew/setDropOffBranch',
	(payload: IBookingNewSetBranchParams) => {
		return { payload };
	},
);

/**
 * Sets the pick-up branch for a new booking.
 *
 * @param {IBookingNewSetBranchParams} payload - The parameters for setting the pick-up branch, including details like branch ID.
 * @param {any} response - The expected response type after setting the pick-up branch. This can be customized based on the response structure provided by the service.
 * @returns {Promise<any>} - A promise that resolves to the response type defined, indicating the outcome of setting the pick-up branch.
 */
export const bookingNewSetPickUpBranch = createAsyncAction<
	IBookingNewSetPickUpBranchPayloadType,
	IBookingNewSetBranchParams
>('bookingNew/setPickUpBranch', bookingNewServiceSetPickUpBranch);

/**
 * Sets the customer details for a new booking.
 *
 * @param {ICustomer} payload - The customer details to be set for the booking.
 */
export const bookingNewSetCustomer = createSyncAction<ICustomer | null>('bookingNew/setCustomer');

/**
 * Sets the type of the customer to whom the invoice is to be sent as a customer for a new booking.
 *
 * @param {ICustomer} payload - The customer details for invoicing.
 */
export const bookingNewInvoiceToCustomerTypeToCustomer = createAction<ICustomer>(
	'bookingNew/invoiceToCustomerTypeToCustomer',
);

/**
 * Sets the type of the customer to whom the invoice is to be sent as a company for a new booking.
 *
 * @param {ICompany} payload - The company details for invoicing.
 */
export const bookingNewInvoiceToCustomerTypeToComapany = createAction<ICompany>(
	'bookingNew/invoiceToCustomerTypeToComapany',
);

/**
 * Sets a discount for a new booking.
 *
 * @param {IBookingNewSetDiscountParams} payload - The discount parameters to be set for the booking.
 */
export const bookingNewSetDiscount = createAction('bookingNew/setDiscount', (params: IBookingNewSetDiscountParams) => {
	const { bookingLines, autoAppliedDiscount, discount } = params;
	const bookingNewDiscount = new BookingDiscount(bookingLines, autoAppliedDiscount, discount);

	return {
		payload: {
			bookingLines: bookingNewDiscount.discountData.updateBookingLines,
			discount: bookingNewDiscount.discountData.code ? 0 : discount,
			currentAppliedDiscount: {
				code: bookingNewDiscount.discountData.code,
				percentage: bookingNewDiscount.discountData.percentage,
				amount: bookingNewDiscount.discountData.amount,
			},
		},
	};
});

/**
 * Sets the method of creation for a new booking.
 *
 * @param {CreationMethod} payload - The method of creation (e.g., online, in-office) for the booking.
 */
export const bookingNewSetCreationMethod = createAction<CreationMethod>('bookingNew/setCreationMethod');

/**
 * Sets cover options for a new booking.
 *
 * @param {IBookingNewSetCoverParams} payload - The cover parameters to be set for the booking.
 */
export const bookingNewSetCover = createAction('bookingNew/setCover', (params: IBookingNewSetCoverParams) => {
	const { bookingLines, service, type, services = [] } = params;

	let updateBookingLines = getUpdateAddBookingLines(bookingLines, service);
	let updateServices = addService(services, service);

	if (type === BookingCoverType.Basic) {
		updateBookingLines = getUpdateRemoveBookingLines(bookingLines, service.code);
		updateServices = removeService(services, service.code);
	}

	if (!service) {
		throw new Error(`Service property is required. This value is required to set cover`);
	}

	return {
		payload: {
			bookingLines: updateBookingLines,
			services: updateServices,
			type,
		},
	};
});

/**
 * Sets whether a payment card is used for a new booking.
 *
 * @param {boolean} payload - A boolean indicating whether a payment card is used.
 */
export const bookingNewSetPaymentCard = createAction<boolean>('bookingNew/setPaymentCard');

/**
 * Sets the flight or train number associated with a new booking.
 *
 * @param {string} payload - The flight or train number.
 */
export const bookingNewSetFlightTrainNumber = createSyncAction<string>('bookingNew/setFlightTrainNumber');

/**
 * Sets the current provider for a new booking.
 *
 * @param {IProvider} payload - The provider information to be set for the booking.
 */
export const bookingNewSetCurrentProvider = createAction<IProvider>('bookingNew/setCurrentProvider');

/**
 * Generate initial data from group and package
 * **Payload**
 *- `bookingLines: IBookingLine[]`: Initial booking lines from services
 *- `services: IService[]`: Services from package and mandatory services
 *- `vehicleGroup: IVehicleGroupAvailabilityAndPrice`: Vehicle group selected;
 *- `packageCode`: Package code selected
 */
export const bookingNewSetVehicleGroupAndPackage = createAction(
	'bookingNew/setVehicleGroupAndPackage',
	(params: IBookingNewSetVehicleGroupAndPackageParams) => {
		const {
			vehicleGroup,
			bookingPackage,
			currentServices,
			provider,
			creationMethod = CreationMethod.WEB,
			pickUpBranch,
		} = params;

		if (!vehicleGroup) {
			throw new Error(`vehicleGroup is required`);
		}

		if (!provider) {
			throw new Error(`provider is required`);
		}

		if (!bookingPackage) {
			throw new Error(`bookingPackage  value is required`);
		}

		let cover: IService | null = null;
		let paymentOnPickUpService: IService | null | undefined = null;
		let coverType: BookingCoverType | null = null;

		const services = getInitialServices(provider, vehicleGroup, bookingPackage.code, currentServices || []);
		const bookingLines = getInitialBookingLines(
			provider,
			vehicleGroup,
			bookingPackage.code,
			currentServices || [],
			pickUpBranch,
		);

		// Marcamos los servicios que se pueden seleccionar en el listado
		const vehicleGroupServices = checkChoosableServices(
			vehicleGroup.services,
			provider,
			creationMethod,
			bookingPackage,
		);

		// ESTABLECER EL CUPO DATA EN LA RESERVA
		let discountData: IBookingNewDiscountData | null = null;
		if (vehicleGroup.discount && vehicleGroup.discount.percentage > 0) {
			discountData = {
				code: vehicleGroup.discount.code ? vehicleGroup.discount.code : '',
				percentage: vehicleGroup.discount.percentage,
			};
		}

		vehicleGroupServices.forEach((service) => {
			/**
			 * Si el paquete no es premium se establece el SC
			 */
			if (provider.smartCover && provider.smartCover === service.code) {
				// Si no es premium, enviamos el cover service
				if (bookingPackage.code === BookingPackageType.Premium) {
					coverType = BookingCoverType.Smart;
					cover = null;
				} else {
					cover = { ...service };
				}
			}

			// Pago en destino
			paymentOnPickUpService = vehicleGroup.services.find(
				(serv) => serv.code === provider.paymentOnPickUp && serv.maximumQuantity > 0,
			);
		});

		return {
			payload: {
				bookingLines,
				bookingPackage,
				cover,
				coverType,
				discountData: discountData || undefined,
				paymentOnPickUpService,
				services,
				vehicleGroup: {
					...vehicleGroup,
					services: vehicleGroupServices,
				},
			},
		};
	},
);

/**
 * Update current paymentLines
 *
 * @param {IBookingNewAddPaymentWithPointsParams} payload - The parameters for adding a payment option using points in the new booking.
 */
export const bookingNewAddPaymentWithPoints = createAction(
	'bookingNew/addPaymentWithPoints',
	(params: IBookingNewAddPaymentWithPointsParams) => {
		const { customer, provider, agencyCode, couponData, discountData, pointsData } = params;

		if (!provider) {
			throw new Error(`provider Can not be ${provider}. This value is required`);
		}

		if (!customer) {
			throw new Error(`Customer Can not be ${customer}. This value is required`);
		}

		if (customer && customer.customerRentType !== CustomerRentType.GoldClub) {
			throw new Error(
				`Only GoldClub customer type can use points. If the customer does not meet this condition, it should not show him this option`,
			);
		} else if (customer && customer.points && customer.points < provider.minimumPointsGC) {
			throw new Error(
				`Minimum ${provider.minimumPointsGC} points are required to be able to use them. If the customer does not meet this condition, it should not show him this option`,
			);
		}

		const { bookingLines, paymentLines } = recalculateDiscounts({
			agencyCode,
			bookingLines: params.bookingLines,
			couponData,
			creationMethod: CreationMethod.WEB,
			customer,
			discountData,
			pointsData,
			provider,
		});

		return {
			payload: {
				bookingLines,
				paymentLines,
				pointsData,
			},
		};
	},
);

/**
 * Removes a payment option using points from the new booking.
 *
 * @param {IBookingNewRemovePaymentWithPointsParams} payload - The parameters for removing a payment option using points from the new booking.
 */
export const bookingNewRemovePaymentWithPoints = createAction(
	'bookingNew/removePaymentWithPoints',
	(params: IBookingNewRemovePaymentWithPointsParams) => {
		const { customer, provider, bookingLines, agencyCode, couponData, discountData, pointsData } = params;

		const { bookingLines: newBookingLines, paymentLines: newPaymentsLines } = recalculateDiscounts({
			agencyCode,
			bookingLines,
			couponData,
			creationMethod: CreationMethod.WEB,
			customer,
			discountData,
			pointsData,
			provider,
		});

		return {
			payload: {
				bookingLines: newBookingLines,
				paymentLines: newPaymentsLines,
				pointsData,
			},
		};
	},
);

/**
 * Sets a discount coupon for a new booking.
 *
 * @param {IBookingNewSetDiscountCouponParams} payload - The parameters for setting the discount coupon, including the coupon code and related booking details.
 * @param {any} response - The expected response type after setting the discount coupon. This can be customized based on the response structure provided by the service.
 * @returns {Promise<any>} - A promise that resolves to the response type defined, indicating the outcome of setting the discount coupon.
 */
export const bookingNewSetDiscountCoupon = createAsyncAction<
	IBookingNewSetDiscountCouponPayload,
	IBookingNewSetDiscountCouponParams
>('bookingNew/setDiscountCoupon', bookingNewServiceSetDiscountCoupon);

/**
 * Removes a previously set discount coupon from a new booking.
 *
 * @param {IBookingNewRemoveDiscountCouponParams} payload - The parameters for removing the discount coupon, including the coupon code and related booking details.
 */
export const bookingNewRemoveDiscountCoupon = createAction(
	'bookingNew/removeDiscountCoupon',
	(params: IBookingNewRemoveDiscountCouponParams) => {
		const { bookingLines, paymentLines } = recalculateDiscounts({
			agencyCode: params.agencyCode,
			bookingLines: params.bookingLines,
			couponData: params.couponData,
			creationMethod: CreationMethod.WEB,
			customer: params.customer,
			discountData: params.discountData,
			pointsData: params.pointsData,
			provider: params.provider,
		});

		return {
			payload: {
				bookingLines,
				couponData: params.couponData,
				discountData: params.discountData,
				paymentLines,
			},
		};
	},
);

/**
 * Applies a filter to the car list in the new booking process.
 *
 * @param {IBookingNewFilterCarListParams} payload - The parameters for filtering the car list.
 */
//! ACCIÓN FANTASMA
export const bookingNewFilterCarList = createAction(
	'bookingNew/filterCarList',
	(params: IBookingNewRemoveDiscountCouponParams) => {
		const { bookingLines, paymentLines } = recalculateDiscounts({
			agencyCode: params.agencyCode,
			bookingLines: params.bookingLines,
			couponData: params.couponData,
			creationMethod: CreationMethod.WEB,
			customer: params.customer,
			discountData: params.discountData,
			pointsData: params.pointsData,
			provider: params.provider,
		});

		return {
			payload: {
				bookingLines,
				couponData: params.couponData,
				discountData: params.discountData,
				paymentLines,
			},
		};
	},
);

/**
 * Sets the agency information for a new external booking.
 *
 * @param {IBookingNewExternalSetAgency} payload - The agency information to be set for the booking.
 */
export const bookingNewExternalSetAgency = createAction(
	'bookingNew/setAgency',
	(payload: IBookingNewExternalSetAgency) => ({ payload }),
);

/**
 * Sets the vendor information for a new external booking.
 *
 * @param {IBookingNewExternalSetVendor} payload - The vendor information to be set for the booking.
 */
export const bookingNewExternalSetVendor = createAction(
	'bookingNew/setVendor',
	(payload: IBookingNewExternalSetVendor) => ({ payload }),
);

/**
 * Sets a coupon for the new booking.
 *
 * @param {IBookingNewSetCoupon} payload - The coupon to be applied to the booking.
 */
export const bookingNewSetCoupon = createAction<IBookingNewSetCoupon>('bookingNew/setCoupon');

/**
 * Sets a filter package for the new booking.
 *
 * @param {IBookingNewSetFilterPackageParams} payload - The parameters for setting the filter package.
 */
export const bookingNewSetFilterPackage =
	createAction<IBookingNewSetFilterPackageParams>('bookingNew/setFilterPackage');

/**
 * Sets the group information for a new partners booking.
 *
 * @param {IPartnersBookingNewSetGroupParams} payload - The group parameters to be set for the partners booking.
 */
export const partnersBookingNewSetGroup = createAction(
	'partnersBookingNew/setGroup',
	(params: IPartnersBookingNewSetGroupParams) => {
		const { booking, partner, group, packageCode } = params;

		const payload = getUpdateBookingNewState(booking, group, packageCode, partner);

		return {
			payload,
		};
	},
);

/**
 * Sets a new vehicle group filter for booking.
 *
 * @param {string} payload - The vehicle group filter to be set.
 */
export const bookingNewSetVehicleGroupFilter = createAction<string | undefined>('bookingNew/setVehicleGroupFilter');

/**
 * Toggles the option for return on a different office in a new booking.
 *
 * @param {boolean} payload - The boolean status to toggle return on different office option.
 */
export const bookingNewToggleReturnOnDifferentOffice = createAction<boolean>(
	'bookingNew/toggleReturnOnDifferentOffice',
);

/**
 * Toggles the option for booking by hours in a new booking.
 *
 * @param {boolean} payload - The boolean status to toggle booking by hours option.
 */
export const bookingNewToggleBookingByHours = createAction<boolean>('bookingNew/toggleBookingByHours');

/**
 * Sets the status for long duration subscription in a new booking.
 *
 * @param {boolean} payload - The boolean status to set for long duration subscription.
 */
export const bookingNewSetIsLongDurationSubscription = createAction<boolean>(
	'bookingNew/setIsLongDurationSubscription',
);

/**
 * Sets the unit of time for a new booking.
 *
 * @param {UnitTime} payload - The unit of time to be set.
 */
export const bookingNewSetUnitTime = createAction<UnitTime>('bookingNew/setUnitTime');
