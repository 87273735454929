import type { IFine } from '../../models/entities/Fine';
import type {
	IFineGetByBookingParams,
	IFineGetByIdParams,
	IFineGetByPlateNumberParams,
	IFineInsertParams,
	IFineModifyParams,
	IFineSearchParams,
} from '../../models/serviceParams/FineParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { FineEndPoints } from '../endPoints/FineEndPoints';

/**
 * @deprecated - use 'fineSearchService' from 'packages\core\src\modules\fleet\fine\services\FineSearchService.ts' instead.
 * Search a fine
 * @return {Promise<FineType>} `FineType`
 */
export const fineServiceSearch = (model: IFineSearchParams): Promise<ServiceResponse<IFine[]>> => {
	return Api.get<IFine[], IFineSearchParams>(FineEndPoints.SEARCH, model);
};

/**
 * @deprecated - use 'fineInsertService' from 'packages\core\src\modules\fleet\fine\services\FineInsertService.ts' instead.
 * Insert a fine
 * @return {Promise<FineType>} `FineType`
 */
export const fineServiceInsert = (model: IFineInsertParams): Promise<ServiceResponse<IFine>> => {
	return Api.post<IFine, IFineInsertParams>(FineEndPoints.INSERT, model);
};

/**
 * @deprecated - use 'fineModifyService' from 'packages\core\src\modules\fleet\fine\services\FineModifyService.ts' instead.
 * Modify a fine
 * @return {Promise<FineType>} `FineType`
 */
export const fineServiceModify = (model: IFineModifyParams): Promise<ServiceResponse<IFine>> => {
	return Api.post<IFine, IFineModifyParams>(FineEndPoints.MODIFY, model);
};

/**
 * @deprecated - use 'fineGetByBookingService' from 'packages\core\src\modules\fleet\fine\services\FineGetByBookingService.ts' instead.
 * Get list fine by booking number
 * @param {string} bookingNumber
 */
export const fineServiceGetByBooking = (model: IFineGetByBookingParams): Promise<ServiceResponse<IFine[]>> => {
	return Api.get<IFine[], IFineGetByBookingParams>(FineEndPoints.GET_BY_BOOKING, model);
};

/**
 * @deprecated - use 'fineGetByPlateNumberService' from 'packages\core\src\modules\fleet\fine\services\FineGetByPlateNumberService.ts' instead.
 * Get list fine by plate number
 * @param {string} plateNumber
 */
export const fineServiceGetByPlateNumber = (model: IFineGetByPlateNumberParams): Promise<ServiceResponse<IFine[]>> => {
	return Api.get<IFine[], IFineGetByPlateNumberParams>(FineEndPoints.GET_BY_PLATENUMBER, model);
};

/**
 * @deprecated - use 'fineServiceGetById' from 'packages\core\src\modules\fleet\fine\services\FineGetByIdService.ts' instead.
 * Get fine by ID
 * @param {string} id fine ID
 */
export const fineServiceGetById = (model: IFineGetByIdParams): Promise<ServiceResponse<IFine>> => {
	return Api.get<IFine, IFineGetByIdParams>(FineEndPoints.GET_BY_ID, model);
};
