import { DateTime } from 'luxon';

import {
	getBookingAmounts,
	getBookingSummaryAmounts,
	getBookingTotals,
	getMaxPointPaymentAmount,
} from './BookingAmounts';
import { setBookingLinesInvoiceTo } from './BookingInvoiceTo';
import {
	getBookingLineFromService,
	getDepositBookingLine,
	getFuelBookingLine,
	getInitialBookingLines,
} from './BookingLines';
import { getPackageFromGroup } from './BookingPackages';
import { getInitialServices } from './BookingService';
import { BookingDiscount } from '../../booking/business/BookingDiscount';
import { round } from '../../helpers/numbers';
import type { IVehicleGroupAvailabilityAndPrice } from '../../models/entities/Availability';
import type { IBookingPackage } from '../../models/entities/BookingPackage';
import type { ICustomer } from '../../models/entities/Customer';
import type { IPartner } from '../../models/entities/Partner';
import type { IPaymentLine } from '../../models/entities/PaymentLine';
import type { IProvider } from '../../models/entities/Provider';
import type { IService } from '../../models/entities/Service';
import type { IBookingInsertExternalParams } from '../../models/serviceParams/BookingParams';
import { BookingDeliveryType } from '../../models/types/BookingDeliveryType';
import { CustomerType } from '../../models/types/CustomerType';
import { PaymentChannelType, PaymentMethodType, PaymentOperationType } from '../../models/types/PaymentType';
import type { IBookingLine } from '../../modules/booking/bookingLine/entities/BookingLine';
import type { IBookingLineInsertParams } from '../../modules/booking/bookingLine/services/BookingLineInsertService';
import { BookingLineType } from '../../modules/booking/bookingLine/types/BookingLineType';
import type { CreationMethod } from '../../modules/shared/types/CreationMethod';
import type {
	IBookingNewCouponData,
	IBookingNewDiscountData,
	IBookingNewPointsData,
} from '../../redux-store/actionsParams/BookingNewActionsParams';
import type {
	IAvailabilityFormValue,
	IBookingNewExternalReducerState,
	IBookingNewInsertReducerState,
	IBookingNewReducerState,
} from '../../redux-store/reducersState/booking/BookingNewReducerState';

/**
 * Add services
 */
export const addService = (addedServices: IService[], serviceToAdd: IService): IService[] => {
	if (addedServices.some((service) => service.code === serviceToAdd.code) && serviceToAdd.maximumQuantity === 1) {
		return addedServices;
	}

	if (
		serviceToAdd.maximumQuantity > 1 &&
		addedServices.filter((service) => service.code === serviceToAdd.code).length === serviceToAdd.maximumQuantity
	) {
		return addedServices;
	}

	if (serviceToAdd.excludes.length > 0) {
		const filteredServices = addedServices.filter(({ code }) => !serviceToAdd.excludes.includes(code));
		return [...filteredServices, serviceToAdd];
	}

	return [...addedServices, serviceToAdd];
};

/**
 * Chck if booking is delivery and return ED service
 * @param provider Curent provider
 * @param services Service list
 * @param values Form availability values
 */
export const getDeliveryServices = (
	provider: IProvider,
	services: IService[],
	values?: IAvailabilityFormValue | null,
): IService[] | null => {
	if (values === null || values === undefined) {
		return null;
	}

	const deliveryServices: IService[] = [];

	if (values.pickUpDeliveryType === BookingDeliveryType.address) {
		const DEService = services.find((service) => service.code === provider.extraDelivery);

		if (DEService) {
			deliveryServices.push(DEService);
		}
	}

	if (values.dropOffDeliveryType === BookingDeliveryType.address) {
		const CEService = services.find((service) => service.code === provider.extraDeliveryDropOff);

		if (CEService) {
			deliveryServices.push(CEService);
		}
	}

	if (deliveryServices.length > 0) {
		return deliveryServices;
	}

	return null;
};

/**
 * Remove service from list
 * @param services curren services list
 * @param code remove service code
 */
export const removeService = (services: IService[], code: string): IService[] => {
	const otherServices = services.filter((item) => item.code !== code);
	const serviceToRemove = services.filter((item) => item.code === code);
	return [...otherServices, ...serviceToRemove.slice(0, serviceToRemove.length - 1)];
};

/**
 * Add booking line base on service
 * @param state BookingNewState
 * @param service Service to add
 */
export const addBookingLine = (state: IBookingNewReducerState, service: IService): IBookingNewInsertReducerState => {
	const { booking, currentProvider, package: bookingPackage } = state;
	if (booking && booking.bookingLines) {
		let updateBookingLines = booking.bookingLines;

		updateBookingLines.push(getBookingLineFromService(booking.bookingLines, service));
		updateBookingLines = setBookingLinesInvoiceTo(state, updateBookingLines, bookingPackage);

		// REMOVE CR IF EXIST
		if (
			currentProvider &&
			service.ff &&
			updateBookingLines.some((line) => line.code === currentProvider.refuelServiceCode)
		) {
			updateBookingLines = [
				...updateBookingLines.filter((line) => line.code !== currentProvider.refuelServiceCode),
			];
		}

		return {
			...booking,
			bookingLines: [...updateBookingLines],
		};
	}

	return state.booking as IBookingNewInsertReducerState;
};

/**
 * Remove booking line from state, base in service
 * @param state BookingState
 * @param serviceCode Service model
 */
export const removeBookingLine = (
	state: IBookingNewReducerState,
	serviceCode: string,
): IBookingNewInsertReducerState => {
	const { booking, currentProvider, group } = state;

	if (booking && booking.bookingLines) {
		const bookingLinesByCode = booking.bookingLines.filter((line) => line.code === serviceCode);
		const currentBookingLines = booking.bookingLines.filter((lines) => lines.code !== serviceCode);

		// ADD CR, IF REMOVE FF
		if (currentProvider && currentProvider.refuelServiceCode && group && group.services) {
			const { services } = group;
			const ffCodes = services.filter((serv) => serv.ff).map((ffService) => ffService.code);

			if (ffCodes.includes(serviceCode)) {
				const crService = services.find(
					(serv) => serv.code === currentProvider.refuelServiceCode && serv.maximumQuantity > 0,
				);
				if (crService) {
					currentBookingLines.push(
						getBookingLineFromService(booking.bookingLines, { ...crService, minimumQuantity: 1 }),
					);
				}
			}
		}

		return {
			...booking,
			bookingLines: [...currentBookingLines, ...bookingLinesByCode.slice(0, bookingLinesByCode.length - 1)],
		};
	}

	return state.booking as IBookingNewInsertReducerState;
};

/**
 * Update booking line in Booking state line list
 * @param state BookingNewState
 * @param bookingLine BookingLine
 */
export const updateBookingLine = (
	state: IBookingNewReducerState,
	bookingLine: IBookingLine,
): IBookingNewInsertReducerState => {
	const { booking } = state;

	if (booking && booking.bookingLines) {
		const currentBookingLines = booking.bookingLines.filter((lines) => lines.key !== bookingLine.key);

		return {
			...booking,
			bookingLines: [...currentBookingLines, bookingLine],
		};
	}

	return state.booking as IBookingNewInsertReducerState;
};

/**
 *	Return all services selected, width new group and package selected
 * @param initialServices Required initial services
 * @param currentServices Current services selected
 * @param group Vehicle group selected
 * @param currentPackage Current package selected
 */
const getCurrentSelectedServices = (
	initialServices: IService[],
	currentServices: IService[],
	group: IVehicleGroupAvailabilityAndPrice,
	currentPackage: IBookingPackage | null,
	booking: IBookingNewInsertReducerState | null,
) => {
	if (booking) {
		const { bookingLines } = booking;
		const currentSelectedServices: IService[] = [];
		const serviceLastPackage = bookingLines
			? bookingLines.filter((line) => line.package).map((line) => line.code)
			: [];

		currentServices.forEach((service) => {
			const currentService = group.services.find((item) => item.code === service.code);
			const initialService = initialServices.find((item) => item.code === service.code);

			if (
				!initialService &&
				currentService &&
				currentService.maximumQuantity > 0 &&
				currentService.choosable &&
				!currentService.included
			) {
				currentSelectedServices.push(service);
			}
		});

		return currentSelectedServices.filter((service) => !serviceLastPackage.includes(service.code));
	}

	return [];
};

/**
 * Update state using group and package if is available
 * @param state IBookingNewReducerState
 * @param group IVehicleGroupAvailability
 * @param packageCode Package code
 */
export const bookingNewSetGroup = (
	state: IBookingNewReducerState,
	group: IVehicleGroupAvailabilityAndPrice,
	packageCode?: string,
	partner?: IPartner,
): IBookingNewReducerState => {
	if (packageCode && !group.packages.find((item) => item.code === packageCode)) {
		// eslint-disable-next-line
		packageCode = group.packages[0]?.code;
	}
	const { currentProvider, services, booking } = state;
	const selectedPackage = getPackageFromGroup(group, packageCode as string);
	const initialBookingLines = getInitialBookingLines(currentProvider as IProvider, group, packageCode, services);
	const initialServices = getInitialServices(currentProvider as IProvider, group, packageCode, services);

	// CURRENT SERVICES SELECTED
	const currentSelectedServices = getCurrentSelectedServices(
		initialServices,
		services as IService[],
		group,
		selectedPackage,
		booking,
	);

	const currentSelectedBookingLines = currentSelectedServices.map((service) =>
		getBookingLineFromService(initialBookingLines, service),
	);

	/*
	 * Company payment
	 */
	if (partner && currentProvider && partner.paymentTermsCode === currentProvider.partnerPrepaidPaymentTerm) {
		if (group.fuelAmount > 0) {
			initialBookingLines.push(getFuelBookingLine(group, initialBookingLines));
		}

		if (group.depositAmount > 0) {
			initialBookingLines.push(getDepositBookingLine(group, initialBookingLines));
		}
	}

	const updatedBookingLines = setBookingLinesInvoiceTo(
		{ ...state, group, package: selectedPackage },
		[...initialBookingLines, ...currentSelectedBookingLines],
		selectedPackage,
	);

	// POR AHORA NO MOSTRAMOS LA LINEA DE COMBUSTIBLE/DEPOSITO SI SE FACTURA AL CLIENTE
	const bookingLines = updatedBookingLines.filter(
		(line) =>
			![BookingLineType.Deposit, BookingLineType.Fuel].includes(line.bookingLineType) ||
			([BookingLineType.Deposit, BookingLineType.Fuel].includes(line.bookingLineType) && line.invoiceToAgency),
	);

	// Check discount
	const { discountData } = new BookingDiscount(bookingLines, group.discount);

	return {
		...state,
		booking: {
			...state.booking,
			bookingLines: discountData.updateBookingLines,
			package: selectedPackage ? selectedPackage.code : undefined,
			vehicleGroupCodeRequested: group.code,
		},
		group,
		package: selectedPackage,
		services: [...initialServices, ...currentSelectedServices],
		currentAppliedDiscount: {
			amount: discountData.amount,
			percentage: discountData.percentage,
			code: discountData.code,
		},
	};
};

export const getBookingNewInsertModel = (
	newBooking: IBookingNewReducerState,
	flightTrainNumber?: string,
	voucher?: string,
): IBookingInsertExternalParams | null => {
	const {
		booking,
		customer,
		agency,
		vendor,
		group,
		creationMethod,
		availabilityFormValue,
		invoiceToCustomerType,
		invoiceToCustomerCode,
		package: bookingPackage,
		currentAppliedDiscount,
	} = newBooking;

	if (!booking || !agency) {
		return null;
	}
	let services: IBookingLineInsertParams[] = [];
	if (booking.bookingLines) {
		services = booking.bookingLines
			.filter((line) => line.bookingLineType === BookingLineType.Service)
			.map((item) => ({
				automatic: item.automatic as boolean,
				bookingLineType: Number(BookingLineType.Service),
				code: item.code,
				creationMethod: creationMethod as CreationMethod,
				invoiceTo: item.invoiceTo as string,
				invoiceToAgency: Boolean(item.invoiceToAgency),
				package: Boolean(item.package),
				quoteDateTime: DateTime.utc().toJSDate(),
			}));
	}

	const { customerBirthDate } = availabilityFormValue as IAvailabilityFormValue;

	const coupon = currentAppliedDiscount?.code;
	const { discount } = booking;

	return {
		coupon,
		agencyCode: agency.code,
		creationMethod,
		customerBirthDate,
		customerCode: customer && customer.code ? customer.code : undefined,
		customerEmail: customer && customer.email ? customer.email : undefined,
		customerFirstSurname: customer && customer.firstSurname ? customer.firstSurname : undefined,
		customerName: (customer as ICustomer).name as string,
		customerPhoneCountry: customer && customer.phoneCountry ? customer.phoneCountry : undefined,
		customerPhoneNumber: customer && customer.phone ? customer.phone : undefined,
		customerPreferedLanguage: customer && customer.preferedLanguage ? customer.preferedLanguage : undefined,
		customerSecondSurname: customer && customer.secondSurname ? customer.secondSurname : undefined,
		discount: coupon ? undefined : discount,
		dropOffBranchCode: booking.dropOffBranchCode as string,
		dropOffDateTime: booking.dropOffDateTime as string,
		flightTrainNumber,
		invoiceToCustomerCode,
		invoiceToCustomerType,
		package: bookingPackage ? bookingPackage.code : undefined,
		paymentLines: [],
		pickUpBranchCode: booking.pickUpBranchCode as string,
		pickUpDateTime: booking.pickUpDateTime as string,
		quoteDateTime: booking.quoteDateTime || DateTime.local().toJSDate(),
		services,
		vehicleGroupCodeRequested: (group as IVehicleGroupAvailabilityAndPrice).code,
		vendorCode: vendor ? vendor.code : undefined,
		voucher,
	};
};

// FUNCIONES TEMPORALES PARA LA WEB, HAY QUE INTENTAR UNIR ESTA LÓGICA, BASADO EN EL CREATION METHOD
export const getUpdateRemoveBookingLines = (bookingLines: IBookingLine[], serviceCode: string): IBookingLine[] => {
	const bookingLinesByCode = bookingLines.filter((line) => line.code === serviceCode);
	const currentBookingLines = bookingLines.filter((lines) => lines.code !== serviceCode);

	/*
	 * ADD CR, IF REMOVE FF
	 * if (currentProvider && currentProvider.refuelServiceCode && group && group.services) {
	 * 	const { services } = group;
	 * 	const ffCodes = services.filter((serv) => serv.ff).map((ffService) => ffService.code);
	 */

	/*
	 * 	If (ffCodes.includes(serviceCode)) {
	 * 		const crService = services.find((serv) => serv.code === currentProvider.refuelServiceCode);
	 * 		if (crService) {
	 * 			currentBookingLines.push(
	 * 				getBookingLineFromService(booking.bookingLines, { ...crService, minimumQuantity: 1 })
	 * 			);
	 * 		}
	 * 	}
	 * }
	 */

	return [...currentBookingLines, ...bookingLinesByCode.slice(0, bookingLinesByCode.length - 1)];
};

export const getUpdateAddBookingLines = (bookingLines: IBookingLine[], service: IService): IBookingLine[] => {
	let updateBookingLines = [...bookingLines];

	updateBookingLines.push(getBookingLineFromService(bookingLines, service));

	// REMOVE EXCLUDED SERVICES
	if (service.excludes && service.excludes.length > 0) {
		updateBookingLines = [...updateBookingLines.filter((line) => !service.excludes.includes(line.code))];
	}

	return updateBookingLines;
};

export interface IBookingNewRecalculateDiscounts {
	bookingLines: IBookingLine[];
	discountData?: IBookingNewDiscountData;
	couponData?: IBookingNewCouponData;
	pointsData?: IBookingNewPointsData;
	creationMethod: CreationMethod;
	customer?: ICustomer;
	provider: IProvider;
	agencyCode: string;
}

export const recalculateDiscounts = (
	props: IBookingNewRecalculateDiscounts,
): {
	bookingLines: IBookingLine[];
	paymentLines: IPaymentLine[];
} => {
	const { bookingLines, discountData, couponData, pointsData, creationMethod, customer, provider, agencyCode } =
		props;

	// Remove previously applied percentage discount
	const newBookingLines = bookingLines.filter((line) => line.bookingLineType !== BookingLineType.Discount);

	/**
	 * THE ORDER OF DISCOUNTS IS:
	 * 		1. PERCENTAGE OF DISCOUNT
	 * 		2. POINTS
	 * 		3. COUPON
	 */

	// If we have a percentage discount we apply them
	if (discountData) {
		const amounts = getBookingAmounts(newBookingLines);
		const discountAmount = round((amounts.rent.rental.retail * discountData.percentage) / 100, 2);
		if (discountAmount > 0) {
			newBookingLines.push({
				bookingLineType: BookingLineType.Discount,
				code: discountData.code,
				creationMethod,
				key: round(Math.random() * 100000, 0),
				netAmount: 0,
				price: discountAmount,
				quantity: 1,
				quoteDateTime: DateTime.local().toJSON() as string,
				retailAmount: discountAmount,
			});
		}
	}

	const paymentLines: IPaymentLine[] = [];

	// If we have points used
	if (pointsData && pointsData.usePoints) {
		const pointsAmount = getMaxPointPaymentAmount([], newBookingLines, agencyCode, provider, customer);
		if (pointsAmount > 0) {
			paymentLines.push({
				amount: pointsAmount,
				channel: PaymentChannelType.VIRTUAL,
				customerType: CustomerType.RENT,
				dateTime: DateTime.local().toJSON() as string,
				method: PaymentMethodType.POINTS,
				number: round(Math.random() * 100000, 0),
				operation: PaymentOperationType.SALE,
				user: '',
				exchangeRate: 0,
			});
		}
	}

	// If we have coupon
	if (couponData) {
		const amounts = getBookingTotals(paymentLines, newBookingLines);
		const maxCouponAmount = Math.min(amounts.pending.retail, couponData.amount);
		if (maxCouponAmount > 0) {
			paymentLines.push({
				amount: maxCouponAmount,
				channel: PaymentChannelType.WEB,
				couponCode: couponData.code,
				customerType: CustomerType.RENT,
				dateTime: DateTime.local().toJSON() as string,
				method: PaymentMethodType.COUPON,
				number: round(Math.random() * 100000, 0),
				operation: PaymentOperationType.SALE,
				user: '',
				exchangeRate: 0,
			});
		}
	}

	return {
		bookingLines: newBookingLines,
		paymentLines,
	};
};

export const recalculateBooking = (
	vehicleGroups: IVehicleGroupAvailabilityAndPrice[],
	bookingState: IBookingNewExternalReducerState,
): IBookingNewExternalReducerState => {
	// Si el grupo actual no está disponible, lo eliminamos
	if (!vehicleGroups) {
		return {
			...bookingState,
			bookingPackage: null,
			vehicleGroup: null,
		};
	}

	const vehicleGroup = vehicleGroups.find((group) => group.code === bookingState.vehicleGroup?.code);
	if (!vehicleGroup) {
		return {
			...bookingState,
			bookingPackage: null,
			vehicleGroup: null,
		};
	}

	return bookingState;
};

/**
 * Update booking amounts witch current data
 * @param state IBookingNewExternalReducerState
 * @returns IBookingNewExternalReducerState
 */
export const getUpdateBookingNewSummaryAmounts = (
	state: IBookingNewExternalReducerState,
): IBookingNewExternalReducerState => {
	const { provider, bookingLines, paymentLines, paymentOnPickUpService } = state;
	if (provider && bookingLines) {
		const filteredBookingLines = bookingLines.filter((line) => line.code !== provider.paymentOnPickUp);
		const { rentAmounts } = getBookingSummaryAmounts(paymentLines || [], filteredBookingLines);

		const totalWhenPayAtPickup = paymentOnPickUpService
			? rentAmounts.pending.retail + paymentOnPickUpService.amount
			: 0;

		return { ...state, ...rentAmounts, totalWhenPayAtPickup };
	}

	return state;
};
